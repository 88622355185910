import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Store
import { resetStore } from '../../actions'

const initialState = false

export const hostEditModeSlice = createSlice({
  name: 'hostEditMode',
  initialState,
  reducers: {
    setIsEditMode: (_, action: PayloadAction<boolean>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, (_) => initialState)
  },
})

export const { setIsEditMode } = hostEditModeSlice.actions
