// 250 Countries (with zones of control, deserted islands, claimed territories, and antartide)
export const COUNTRIES: { [key: string]: string } = {
  AD: 'Andorra (Andorra)',
  AE: 'دولة الإمارات العربية المتحدة (United Arab Emirates)',
  AF: 'افغانستان (Afghanistan)',
  AG: 'Antigua and Barbuda (Antigua and Barbuda)',
  AI: 'Anguilla (Anguilla)',
  AL: 'Shqipëri (Albania)',
  AM: 'Հայաստան (Armenia)',
  AO: 'Angola (Angola)',
  AQ: 'Antartide (Antarctica)',
  AR: 'Argentina (Argentina)',
  AS: 'Amerika Sāmoa (American Samoa)',
  AT: 'Österreich (Austria)',
  AU: 'Australia (Australia)',
  AW: 'Aruba (Aruba)',
  AX: 'Landskapet Åland (Åland Islands)',
  AZ: 'Azərbaycan (Azerbaijan)',
  BA: 'Bosna i Hercegovina (Bosnia and Herzegovina)',
  BB: 'Barbados (Barbados)',
  BD: 'বাংলাদেশ (Bangladesh)',
  BE: 'België (Belgium)',
  BF: 'Burkina Faso (Burkina Faso)',
  BG: 'България (Bulgaria)',
  BH: 'البحرين‎ (Bahrain)',
  BI: 'Uburundi (Burundi)',
  BJ: 'Bénin (Benin)',
  BL: 'Saint-Barthélemy (Saint Barthélemy)',
  BM: 'Bermuda (Bermuda)',
  BN: 'Negara Brunei Darussalam (Brunei)',
  BO: 'Bolivia (Bolivia)',
  BQ: 'Caribisch Nederland (Caribbean Netherlands)',
  BR: 'Brasil (Brazil)',
  BS: 'Bahamas (Bahamas)',
  BT: 'འབྲུག་ཡུལ་ (Bhutan)',
  BV: 'Bouvetøya (Bouvet Island)',
  BZ: 'Belize (Belize)',
  BW: 'Botswana (Botswana)',
  BY: 'Беларусь (Belarus)',
  CA: 'Canada (Canada)',
  CC: 'Pulu Kokos (Keeling) (Cocos (Keeling) Islands)',
  CD: 'Congo (Democratic Republic of the Congo)',
  CF: 'ödörösêse tî Bêafrîka (Central African Republic)',
  CG: 'Congo (Republic of the Congo)',
  CH: 'Schweizerische Eidgenossenschaft (Switzerland)',
  CI: "Côte d'Ivoire (Ivory Coast)",
  CK: "Kūki 'Āirani (Cook Islands)",
  CL: 'Chile (Chile)',
  CM: 'Cameroun (Cameroon)',
  CN: '中国 (China)',
  CO: 'Colombia (Colombia)',
  CR: 'Costa Rica (Costa Rica)',
  CU: 'Cuba (Cuba)',
  CV: 'Cabo Verde (Cape Verde)',
  CW: 'Kòrsou (Curaçao)',
  CX: 'Christmas Island (Christmas Island)',
  CY: 'Κύπρος (Cyprus)',
  CZ: 'Česká republika (Czech Republic)',
  DE: 'Deutschland (Germany)',
  DJ: 'جيبوتي‎ (Djibouti)',
  DK: 'Danmark (Denmark)',
  DM: 'Dominica (Dominica)',
  DO: 'República Dominicana (Dominican Republic)',
  DZ: 'الجزائر‎ (Algeria)',
  EC: 'Ecuador (Ecuador)',
  EE: 'Eesti (Estonia)',
  EG: 'مِصر‎ (Egypt)',
  EH: 'الصحراء الغربية (Western Sahara)',
  ER: 'ኤርትራ) (Eritrea)',
  ES: 'España (Spain)',
  ET: 'ኢትዮጵያ (Ethiopia)',
  FI: 'Suomi (Finland)',
  FJ: 'Viti (Fiji)',
  FK: 'Islas Malvinas (Falkland Islands)',
  FM: 'FSM (Micronesia)',
  FO: 'Føroyar (Faroe Islands)',
  FR: 'France (France)',
  GA: 'Gabon (Gabon)',
  GB: 'UK (United Kingdom)',
  GD: 'Grenada (Grenada)',
  GE: 'საქართველო (Georgia)',
  GF: 'Guyane (French Guiana)',
  GG: 'Guernési (Guernsey)',
  GQ: 'Guinea Ecuatorial (Equatorial Guinea)',
  GH: 'Ghana (Ghana)',
  GI: 'Gibraltar (Gibraltar)',
  GL: 'Kalaallit Nunaat (Greenland)',
  GM: 'The Gambia (The Gambia)',
  GN: 'République de Guinée (Guinea)',
  GP: 'Guadeloupe (Guadeloupe)',
  GR: 'Ελλάς (Greece)',
  GS: 'SGSSI (South Georgia and the South Sandwich Islands)',
  GT: 'Guatemala (Guatemala)',
  GU: 'Guåhån (Guam)',
  GW: 'República da Guiné-Bissau  (Guinea-Bissau)',
  GY: 'Guyana (Guyana)',
  HK: '香港 (Hong Kong)',
  HM: 'HIMI (Heard Island and Mcdonald Islands)',
  HN: 'Honduras (Honduras)',
  HR: 'Hrvatska (Croatia)',
  HT: 'Haïti (Haiti)',
  HU: 'Magyarország (Hungary)',
  ID: 'Indonesia (Indonesia)',
  IE: 'Éire (Ireland)',
  IL: 'יִשְׂרָאֵל (Israel)',
  IM: 'Ellan Vannin (Isle of Man)',
  IN: 'Bhārat (India)',
  IO: 'BIOT (British Indian Ocean Territory)',
  IQ: 'العراق (Iraq)',
  IR: 'جمهوری اسلامی ایران (Iran)',
  IS: 'Ísland (Iceland)',
  IT: 'Italia (Italy)',
  JE: 'Jèrri (Jersey)',
  JM: 'Jamaica (Jamaica)',
  JO: 'الْأُرْدُنّ‎ (Jordan)',
  JP: '日本 (Japan)',
  KE: 'Kenya (Kenya)',
  KG: 'Кыргызстан (Kyrgyzstan)',
  KH: 'កម្ពុជា (Cambodia)',
  KI: 'Kiribati (Kiribati)',
  KM: 'جزر القمر‎ (Comoros)',
  KN: 'Saint Kitts and Nevis (Saint Kitts and Nevis)',
  KP: '조선 (North Korea)',
  KR: '한국 (South Korea)',
  KW: 'الكويت‎ (Kuwait)',
  KY: 'Cayman Islands (Cayman Islands)',
  KZ: 'Қазақстан (Kazakhstan)',
  LA: 'ລາວ (Laos)',
  LB: 'لبنان‎ (Lebanon)',
  LC: 'Saint Lucia (Saint Lucia)',
  LI: 'Liechtenstein (Liechtenstein)',
  LK: 'ශ්‍රී ලංකා/இலங்கை (Sri Lanka)',
  LR: 'Liberia (Liberia)',
  LS: 'Lesotho (Lesotho)',
  LT: 'Lietuva (Lithuania)',
  LU: 'Lëtzebuerg (Luxembourg)',
  LV: 'Latvija (Latvia)',
  LY: 'ليبيا‎, (Libya)',
  MA: 'المغرب‎ (Morocco)',
  MC: 'Monaco (Monaco)',
  MD: 'Moldova (Moldova)',
  ME: 'Црна Гора (Montenegro)',
  MF: 'Collectivité de Saint-Martin (Saint Martin)',
  MG: 'Madagasikara (Madagascar)',
  MH: 'Aolepān Aorōkin M̧ajeļ (Marshall Islands)',
  MK: 'Северна Македонија (North Macedonia)',
  ML: 'Mali (Mali)',
  MM: 'ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်‌ (Myanmar)',
  MN: 'Монгол Улс (Mongolia)',
  MO: '澳門 (Macau)',
  MP: 'CNMI (Northern Mariana Islands)',
  MQ: 'Martinique (Martinique)',
  MR: 'الجمهورية الإسلامية الموريتانية (Mauritania)',
  MS: 'Montserrat (Montserrat)',
  MT: 'Malta (Malta)',
  MU: 'Maurice (Mauritius)',
  MV: 'ދިވެހިރާއްޖެ (Maldives)',
  MW: 'Malaŵi (Malawi)',
  MX: 'México (Mexico)',
  MY: 'Malaysia (Malaysia)',
  MZ: 'Moçambique (Mozambique)',
  NA: 'Namibië (Namibia)',
  NC: 'Nouvelle-Calédonie (New Caledonia)',
  NE: 'Niger (Niger)',
  NF: "Norf'k Ailen (Norfolk Island)",
  NG: 'Nijeriya (Nigeria)',
  NI: 'Nicaragua (Nicaragua)',
  NL: 'Nederland (Netherlands)',
  NO: 'Norge (Norway)',
  NP: 'नेपाल (Nepal)',
  NR: 'Naoero (Nauru)',
  NU: 'Niuē (Niue)',
  NZ: 'Aotearoa (New Zealand)',
  OM: 'عمان‎ (Oman)',
  PA: 'Panamá (Panama)',
  PE: 'Perú (Peru)',
  PF: 'Polynésie Française (French Polynesia)',
  PG: 'Papua Niugini (Papua New Guinea)',
  PH: 'Pilipinas (Philippines)',
  PK: 'اِسلامی جمہوریہ پاكِستان (Pakistan)',
  PL: 'Polska (Poland)',
  PM: 'Saint-Pierre-et-Miquelon (Saint Pierre and Miquelon)',
  PN: 'Pitkern Ailen (Pitcairn Islands)',
  PR: 'Puerto Rico (Puerto Rico)',
  PS: 'فلسطين‎ (Palestine)',
  PT: 'Portugal (Portugal)',
  PW: 'Belau (Palau)',
  PY: 'Paraguái (Paraguay)',
  QA: 'قطر‎ (Qatar)',
  RE: 'La Réunion (Réunion)',
  RO: 'România (Romania)',
  RS: 'Србија (Serbia)',
  RU: 'Росси́я (Russia)',
  RW: 'U Rwanda (Rwanda)',
  SA: 'المملكة العربية السعودية (Saudi Arabia)',
  SB: 'Solomon Islands (Solomon Islands)',
  SC: 'Repiblik Sesel (Seychelles)',
  SD: 'السودان (Sudan)',
  SE: 'Sverige (Sweden)',
  SG: 'Republik Singapura (Singapore)',
  SH: 'Saint Helena (Saint Helena)',
  SI: 'Slovenija (Slovenia)',
  SJ: 'Svalbard og Jan Mayen (Svalbard and Jan Mayen)',
  SK: 'Slovensko (Slovakia)',
  SL: 'Sierra Leone (Sierra Leone)',
  SM: 'San Marino (San Marino)',
  SN: 'Sénégal (Senegal)',
  SO: 'Soomaaliya (Somalia)',
  SR: 'Suriname (Suriname)',
  SS: 'South Sudan (South Sudan)',
  ST: 'São Tomé e Príncipe (São Tomé and Príncipe)',
  SV: 'El Salvador (El Salvador)',
  SX: 'Sint Maarten (Saint Martin)',
  SY: 'سوريا‎ (Syria)',
  SZ: 'eSwatini (Eswatini)',
  TC: 'TCI (Turks and Caicos Islands)',
  TD: 'تشاد‎ (Chad)',
  TF: 'TAAF (French Southern and Antarctic Lands)',
  TG: 'République togolaise (Togo)',
  TH: 'ราชอาณาจักรไทย (Thailand)',
  TJ: 'Тоҷикистон (Tajikistan)',
  TK: 'Tokelau (Tokelau)',
  TL: "Timor-Leste/Timór Lorosa'e (East Timor)",
  TM: 'Türkmenistan (Turkmenistan)',
  TN: 'الجمهورية التونسية (Tunisia)',
  TO: 'Tonga (Tonga)',
  TR: 'Türkiye (Turkey)',
  TT: 'Trinidad and Tobago (Trinidad and Tobago)',
  TV: 'Tuvalu (Tuvalu)',
  TW: '中華民國 (Taiwan)',
  TZ: 'Tanzania (Tanzania)',
  UA: 'Україна (Ukraine)',
  UG: 'Uganda (Uganda)',
  UM: 'USMOI (United States Minor Outlying Islands)',
  US: 'USA (United States of America)',
  UY: 'Uruguay (Uruguay)',
  UZ: 'Oʻzbekiston (Uzbekistan)',
  VA: 'Status Civitatis Vaticanae (Vatican City)',
  VC: 'Saint Vincent and the Grenadines (Saint Vincent)',
  VE: 'Venezuela (Venezuela)',
  VG: 'BVI (British Virgin Islands)',
  VI: 'USVI (United States Virgin Islands)',
  VN: 'Việt Nam (Vietnam)',
  VU: 'Vanuatu (Vanuatu)',
  WF: 'Wallis-et-Futuna (Wallis and Futuna)',
  WS: 'Sāmoa (Samoa)',
  XK: 'Kosova (Kosovo)',
  YE: 'ٱلْيَمَن‎ (Yemen)',
  YT: 'Mayotte (Mayotte)',
  ZA: 'RSA (South Africa)',
  ZM: 'Zambia (Zambia)',
  ZW: 'Zimbabwe (Zimbabwe)',
}

export const COUNTRY_KEYS = Object.keys(COUNTRIES).sort(
  (keyA: string, keyB: string) => {
    const valueA = COUNTRIES[keyA]
    const valueB = COUNTRIES[keyB]

    if (valueA > valueB) {
      return 1
    }
    if (valueA < valueB) {
      return -1
    }

    return 0
  }
)
