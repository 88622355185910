import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Libs
import { GuestLocale } from '../../../lib/types'

const initialState = {
  language: '',
  country: '',
  locale: '',
} as GuestLocale

export const guestLocaleSlice = createSlice({
  name: 'guestLocale',
  initialState,
  reducers: {
    setGuestLocale: {
      reducer: (_, action: PayloadAction<GuestLocale>) => action.payload,
      prepare: ({ language, country }: { language: string; country?: string }) => {
        let locale = language
        if (!!country) {
          locale = `${locale}-${country}`
        }
        return { payload: { language, country, locale } }
      },
    },
  },
})

export const { setGuestLocale } = guestLocaleSlice.actions
