import React from 'react'
import styled from 'styled-components'
// Libs
import { SPINNER_SIZE } from '../../../lib/theme'
// Components
import { Spinner } from '../../atoms/Spinner'

const CenteredContainer = styled.div`
  height: ${SPINNER_SIZE}px;
  margin: 16px auto;
  width: ${SPINNER_SIZE}px;
`

export const BigSpinner = ({
  color,
  duration,
  delayMS,
  className,
}: {
  color?: string
  duration?: number
  delayMS?: number
  className?: any
}) => (
  <div>
    <CenteredContainer>
      <Spinner
        className={className}
        color={color}
        size={`${SPINNER_SIZE}px`}
        delayMS={delayMS}
        duration={duration}
      />
    </CenteredContainer>
  </div>
)
