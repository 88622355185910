import i18next, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import XHR from 'i18next-xhr-backend'
import React, { createContext, useState, useEffect, useCallback } from 'react'
// Libs
import {
  getSupportedGuestAppLanguage,
  getSupportedHostAppLanguage,
  getSupportedGuestAppExtraLanguage,
} from '../constants'

export interface I18n {
  t: (value: any, options?: any) => any
  dir: () => string
  language: string
  changeLanguage: (language: string) => Promise<I18n>
  use: (props: any) => any
  reloadResources: (languagesToLoad: any, namespacesToLoad: any) => Promise<any>
}
interface I18nContextState {
  i18n: I18n
  appLanguage: string
  changeAppLanguage: (language: string) => void
  isRtl: boolean // derived value, use to refresh the context if changes ;)
}

export enum I18nNamespace {
  Core = 'Core',
  Extra = 'Extra',
  Pro = 'Pro',
}

// Namespaces
interface I18nContextState {
  currentNamespace: I18nNamespace
  setCurrentNamespace: (namespace: I18nNamespace) => void
}

export const I18nContext = createContext({} as I18nContextState)

const initOptions: InitOptions = {
  // debug: true,
  fallbackLng: {
    'zh-TW': ['zh-Hant', 'en'],
    'zh-CN': ['zh-Hans', 'en'],
    default: ['en'],
  },
  // resources: { en: { core: core_en_json } }, // resource to start the app with; // { ingredients: core_ingredients_en_json }
  defaultNS: 'core',
  ns: ['core'], // 'ingredients'],
  // load: 'all', // load 'all' is default -> tries for en-US, and then for en
  initImmediate: true, // false means it will wait syncronously
  interpolation: {
    escapeValue: false, // React already does escaping
  },
  detection: {
    order: ['localStorage', 'navigator'], // Possible: 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'
    lookupLocalStorage: 'i18nextLng',
  },
  backend: {
    backends: [
      LocalStorageBackend, // primary
      XHR, // fallback
    ],
    backendOptions: [
      {
        // prefix for stored languages
        prefix: 'i18next_res_',
        // expiration
        expirationTime: 30 * 24 * 60 * 60 * 1000, // 30 days
        // language versions
        versions: {
          // ar: '0.0.1',
          ca: '1.8.2',
          de: '1.8.2',
          en: '1.8.2',
          es: '1.8.2',
          fr: '1.8.2',
          it: '1.8.2',
        },
        // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
        store: window.localStorage,
      },
      {
        loadPath: '/locales/{{ns}}/{{ns}}_{{lng}}.json', // xhr load path for my own fallback
      },
    ],
  },
}

const hasAllResourceBundles = (
  i18n: any,
  language: string,
  namespaces: string[]
): boolean => {
  for (
    let namespaceIndex = 0;
    namespaceIndex < namespaces.length;
    namespaceIndex++
  ) {
    const namespace = namespaces[namespaceIndex]

    const isBundleLoaded = i18n.hasResourceBundle(language, namespace)
    if (!isBundleLoaded) {
      return false
    }
  }

  return true
}

export const I18nContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const appLanguage = ''

  const [context, setContext] = useState<I18nContextState>({
    i18n: i18next as any,
    appLanguage,
    changeAppLanguage: () => {},
    isRtl: false,

    currentNamespace: I18nNamespace.Core,
    setCurrentNamespace: () => {},
  })

  const onLanguageChanged = useCallback((languageOrLocale: string) => {
    setContext((prevContext) => {
      const { languageToLoad, namespacesToLoad } = getLanguagesAndNamespacesToLoad(
        prevContext.i18n,
        prevContext.currentNamespace
      )

      const isRtl = prevContext.i18n.dir() === 'rtl'

      const areAllBundlesLoaded = hasAllResourceBundles(
        prevContext.i18n,
        languageToLoad,
        namespacesToLoad
      )
      if (!areAllBundlesLoaded) {
        prevContext.i18n.reloadResources(languageToLoad, namespacesToLoad)
      }

      return { ...prevContext, appLanguage: languageToLoad, isRtl }
    })
  }, [])

  useEffect(() => {
    context.i18n
      .use(LanguageDetector)
      .use(Backend)
      // We need to observe the event since it's triggered on first load...
      .on('languageChanged', onLanguageChanged)
      .init(initOptions)

    const changeAppLanguage = (language: string) => {
      context.i18n.changeLanguage!(language)
    }

    const setCurrentNamespace = async (namespace: I18nNamespace) => {
      const { languageToLoad, namespacesToLoad } = getLanguagesAndNamespacesToLoad(
        context.i18n,
        namespace
      )

      const areAllBundlesLoaded = hasAllResourceBundles(
        context.i18n,
        languageToLoad,
        namespacesToLoad
      )
      if (!areAllBundlesLoaded) {
        await context.i18n.reloadResources(languageToLoad, namespacesToLoad)
      }

      setContext((prevContext) => ({
        ...prevContext,
        currentNamespace: namespace,
      }))

      changeAppLanguage(languageToLoad)
    }

    setContext((prevContext) => ({
      ...prevContext,
      changeAppLanguage,
      setCurrentNamespace,
    }))
  }, [context.i18n, onLanguageChanged])

  return <I18nContext.Provider value={context}>{children}</I18nContext.Provider>
}

const getLanguagesAndNamespacesToLoad = (
  i18n: any,
  currentNamespace: I18nNamespace
): {
  // languageToUse: string
  languageToLoad: string
  namespacesToLoad: string[]
} => {
  const languageToLoad =
    currentNamespace === I18nNamespace.Core
      ? getSupportedGuestAppLanguage(i18n.languages)
      : currentNamespace === I18nNamespace.Extra
      ? getSupportedGuestAppExtraLanguage(i18n.languages)
      : getSupportedHostAppLanguage(i18n.languages)

  const namespacesToLoad =
    currentNamespace === I18nNamespace.Core
      ? ['core'] //, 'ingredients']
      : currentNamespace === I18nNamespace.Extra
      ? ['core', 'extra'] //, 'ingredients']
      : ['core', 'pro'] //, 'ingredients']

  return { languageToLoad, namespacesToLoad }
}
