// Libs
import { GuestPlaceData } from '../../../lib/types'

const EMPTY_I18N = { id: '', locale: '' }

export const EMPTY_COORDINATES = {
  latitude: 41.387,
  longitude: 2.17,
}

export const EMPTY_GUEST_PLACE: GuestPlaceData = {
  id: '',
  currency: '',
  country: '',
  name: '',
  urlName: '',
  locale: '',
  otherLocales: [],
  imageName: '',
  coordinates: EMPTY_COORDINATES,
  address: '',
  phone: '',
  email: '',
  webpage: '',
  instagram: '',
  facebook: '',
  twitter: '',

  originalPlaceI18n: EMPTY_I18N,
  currentPlaceI18n: EMPTY_I18N,

  phrasesById: {},
  thingsById: {},
  groupsById: {},

  groupsIds: [],

  isPro: false,
}
