// Fonts
import './fonts.css'
// Styled
import { GlobalStyle } from './GlobalStyle'

// External Libs
import React, { lazy, Suspense } from 'react'
// Libs
import { I18nContextProvider } from '../../lib/contexts'
// Components
import { FullscreenLoader } from '../atoms/FullscreenLoader'

// Lazy!
const LazyRouter = lazy(() => import('../Router'))
const Router = (props: any) => (
  <Suspense fallback={<FullscreenLoader pretendHeader />}>
    <LazyRouter {...props} />
  </Suspense>
)

export const App = () => {
  return (
    <>
      <GlobalStyle />

      <I18nContextProvider>
        <Router />
      </I18nContextProvider>
    </>
  )
}

export default App
