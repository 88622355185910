import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  isMobile: false,
  isSmall: false,
  isDarkModeOn: true,
}

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isMobile: action.payload,
    }),
    setIsSmall: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSmall: action.payload,
    }),
    setIsDarkModeOn: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isDarkModeOn: action.payload,
    }),
  },
})

export const { setIsMobile, setIsSmall, setIsDarkModeOn } = deviceSlice.actions
