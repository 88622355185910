import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { omit } from 'lodash'
import { allergensKeys, AllergenStatus } from '../../../lib/constants'
// Libs
import {
  GuestPlaceData,
  GuestGroupData,
  GuestPhraseOrThing,
  GuestThingData,
  GuestPhraseOrThingReference,
  GuestGroupDataI18n,
  GuestPricePhrase,
  GuestPlaceDataI18n,
  GuestPlacePricePhrase,
  GuestThingDataI18n,
  GuestPhraseData,
  GuestPhraseDataI18n,
  Coordinates,
} from '../../../lib/types'
// Store
import { resetStore } from '../../actions'
// Local
import { EMPTY_GUEST_PLACE } from './emptyData'

const initialState = EMPTY_GUEST_PLACE

export const localPlaceSlice = createSlice({
  name: 'localPlace',
  initialState,
  reducers: {
    resetLocalPlace: () => initialState,
    setLocalPlace: (_, action: PayloadAction<GuestPlaceData>) => action.payload,
    setLocalPlaceOtherLocales: (state, action: PayloadAction<string[]>) => {
      state.otherLocales = action.payload
    },
    setLocalPlaceImage: (state, action: PayloadAction<string>) => {
      state.imageName = action.payload
    },
    setLocalPlaceCoordinates: (state, action: PayloadAction<Coordinates>) => {
      state.coordinates = action.payload
    },
    setLocalPlaceAddress: (state, action: PayloadAction<string | null>) => {
      state.address = action.payload || ''
    },
    setLocalPlacePhone: (state, action: PayloadAction<string | null>) => {
      state.phone = action.payload || ''
    },
    setLocalPlaceWhatsapp: (state, action: PayloadAction<string | null>) => {
      state.whatsapp = action.payload || ''
    },
    setLocalPlaceEmail: (state, action: PayloadAction<string | null>) => {
      state.email = action.payload || ''
    },
    setLocalPlaceWebpage: (state, action: PayloadAction<string | null>) => {
      state.webpage = action.payload || ''
    },
    setLocalPlaceInstagram: (state, action: PayloadAction<string | null>) => {
      state.instagram = action.payload || ''
    },
    setLocalPlaceFacebook: (state, action: PayloadAction<string | null>) => {
      state.facebook = action.payload || ''
    },
    setLocalPlaceTwitter: (state, action: PayloadAction<string | null>) => {
      state.twitter = action.payload || ''
    },
    setLocalPlaceCurrentI18n: (
      state,
      action: PayloadAction<GuestPlaceDataI18n>
    ) => {
      state.currentPlaceI18n = action.payload
    },
    setLocalPlaceCurrentName: (state, action: PayloadAction<string>) => {
      if (!state.currentPlaceI18n || !state.currentPlaceI18n.locale) {
        console.error(
          'setLocalPlaceCurrentName ERROR:',
          current(state.currentPlaceI18n),
          action
        )
        return
      }

      state.currentPlaceI18n.name = action.payload
    },
    setLocalPlaceCurrentDescription: (state, action: PayloadAction<string>) => {
      if (!state.currentPlaceI18n || !state.currentPlaceI18n.locale) {
        console.error(
          'setLocalPlaceCurrentDescription ERROR:',
          current(state.currentPlaceI18n),
          action
        )
        return
      }

      state.currentPlaceI18n.description = action.payload
    },
    setLocalPlaceCurrentFooterDescription: (
      state,
      action: PayloadAction<string>
    ) => {
      if (!state.currentPlaceI18n || !state.currentPlaceI18n.locale) {
        console.error(
          'setLocalPlaceCurrentFooterDescription ERROR:',
          current(state.currentPlaceI18n),
          action
        )
        return
      }

      state.currentPlaceI18n.footerDescription = action.payload
    },
    setLocalPlaceFooterPrices: (
      state,
      action: PayloadAction<GuestPlacePricePhrase[]>
    ) => {
      state.footerPricesPhrases = action.payload
    },
    setLocalPlaceUploadedMenus: (state, action: PayloadAction<string[]>) => {
      state.uploadedMenus = action.payload
    },

    setGroupsIds: (state, action: PayloadAction<string[]>) => {
      state.groupsIds = action.payload
    },
    addLocalGroup: (state, action: PayloadAction<GuestGroupData>) => {
      state.groupsById[action.payload.id] = action.payload
      state.groupsIds.push(action.payload.id)
    },
    addLocalGroupAndThings: (
      state,
      action: PayloadAction<{ group: GuestGroupData; things: GuestThingData[] }>
    ) => {
      const newThingsById = action.payload.things.reduce(
        (accumulator: { [key: string]: GuestThingData }, thing) => {
          accumulator[thing.id] = thing
          return accumulator
        },
        {}
      )

      state.groupsById[action.payload.group.id] = action.payload.group
      state.groupsIds.push(action.payload.group.id)
      state.thingsById = { ...state.thingsById, ...newThingsById }
    },
    setLocalGroupVisibility: (
      state,
      action: PayloadAction<{
        groupId: string
        isHidden: boolean
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group) {
        console.error('setLocalGroupVisibility ERROR:', current(group), action)
        return
      }

      group.isHidden = action.payload.isHidden
    },
    setLocalGroupIndex: (
      state,
      action: PayloadAction<{
        groupId: string
        newIndex: number
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      const groupIndex = state.groupsIds.findIndex(
        (groupId) => groupId === action.payload.groupId
      )
      if (!group || groupIndex === -1) {
        console.error(
          'setLocalGroupIndex ERROR:',
          current(group),
          groupIndex,
          action
        )
        return
      }

      state.groupsIds.splice(groupIndex, 1)
      state.groupsIds.splice(action.payload.newIndex, 0, action.payload.groupId)
    },
    setLocalGroupImage: (
      state,
      action: PayloadAction<{
        groupId: string
        imageName: string
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group) {
        console.error('setLocalGroupImage ERROR:', current(group), action)
        return
      }

      group.imageName = action.payload.imageName
    },
    setLocalGroupOriginalName: (
      state,
      action: PayloadAction<{
        groupId: string
        name: string
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (
        !group ||
        !group.currentGroupI18n ||
        !group.currentGroupI18n.locale ||
        !group.originalGroupI18n ||
        !group.originalGroupI18n.locale ||
        group.currentGroupI18n.locale !== group.originalGroupI18n.locale
      ) {
        console.error('setLocalGroupOriginalName ERROR:', current(group), action)
        return
      }

      group.name = action.payload.name
      group.currentGroupI18n.name = action.payload.name
      group.originalGroupI18n.name = action.payload.name
    },
    setLocalGroupCurrentI18n: (
      state,
      action: PayloadAction<{
        groupId: string
        currentGroupI18n: GuestGroupDataI18n
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group) {
        console.error('setLocalGroupCurrentI18n ERROR:', current(state), action)
        return
      }

      group.currentGroupI18n = action.payload.currentGroupI18n
    },
    setLocalGroupCurrentName: (
      state,
      action: PayloadAction<{
        groupId: string
        name: string
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group || !group.currentGroupI18n || !group.currentGroupI18n.locale) {
        console.error('setLocalGroupCurrentName ERROR:', current(group), action)
        return
      }

      group.currentGroupI18n.name = action.payload.name
    },
    setLocalGroupDescription: (
      state,
      action: PayloadAction<{
        groupId: string
        description: string
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (
        !group ||
        !group.currentGroupI18n ||
        !group.currentGroupI18n.locale ||
        !group.originalGroupI18n ||
        !group.originalGroupI18n.locale
      ) {
        console.error('setLocalGroupDescription ERROR:', current(group), action)
        return state
      }

      if (group.currentGroupI18n.locale === group.originalGroupI18n.locale) {
        group.originalGroupI18n.description = action.payload.description
      }
      group.currentGroupI18n.description = action.payload.description
    },
    setLocalGroupPrices: (
      state,
      action: PayloadAction<{
        groupId: string
        prices: GuestPricePhrase[]
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group) {
        console.error('setLocalGroupPrices ERROR:', current(group), action)
        return state
      }

      group.prices = action.payload.prices
    },
    setLocalGroupPhrasesOrThings: (
      state,
      action: PayloadAction<{
        groupId: string
        phrasesOrThings: GuestPhraseOrThing[]
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group) {
        console.error(
          'setLocalGroupPhrasesOrThings ERROR:',
          current(group),
          action
        )
        return state
      }

      group.phrasesOrThings = action.payload.phrasesOrThings
    },
    setLocalGroupPhraseId: (
      state,
      action: PayloadAction<{
        groupId: string
        phraseLinkId: string
        phraseId: string
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      const phraseIndex =
        !!group && !!group.phrasesOrThings
          ? group.phrasesOrThings.findIndex(
              (phraseOrThing) => phraseOrThing.id === action.payload.phraseLinkId
            )
          : -1
      if (!group || !group.phrasesOrThings || phraseIndex < 0) {
        console.error(
          'setLocalGroupPhraseId ERROR:',
          current(group),
          phraseIndex,
          action
        )
        return
      }

      group.phrasesOrThings[phraseIndex].elementId = action.payload.phraseId
    },
    addPhraseOrLinkToGroup: (
      state,
      action: PayloadAction<{
        groupId: string
        phraseOrThing: GuestPhraseOrThing
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group) {
        console.error('addPhraseOrLinkToGroup ERROR:', current(group), action)
        return
      }

      if (!group.phrasesOrThings || !Array.isArray(group.phrasesOrThings)) {
        group.phrasesOrThings = []
      }

      group.phrasesOrThings.push(action.payload.phraseOrThing)
    },
    deleteLocalGroup: (state, action: PayloadAction<string>) => {
      const group = state.groupsById[action.payload]
      if (!group) {
        console.error('deleteLocalGroup ERROR:', current(group), action)
        return
      }

      state.groupsById = omit(state.groupsById, [group.id])
      state.groupsIds = state.groupsIds.filter((groupId) => groupId !== group.id)
    },

    addLocalThing: (
      state,
      action: PayloadAction<{
        groupId: string
        thing: GuestThingData
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group) {
        console.error('addLocalThing ERROR:', current(group), action)
        return
      }

      group.phrasesOrThings.push({
        id: action.payload.thing.id,
        elementId: action.payload.thing.id,
        orderIndex: group.phrasesOrThings.length,
        type: GuestPhraseOrThingReference.Thing,
      })

      state.thingsById[action.payload.thing.id] = action.payload.thing
    },
    addLocalThings: (
      state,
      action: PayloadAction<{
        groupId: string
        things: GuestThingData[]
      }>
    ) => {
      const group = state.groupsById[action.payload.groupId]
      if (!group) {
        console.error('addLocalThings ERROR:', current(group), action)
        return
      }

      const orderIndexInt = group.phrasesOrThings.length
      group.phrasesOrThings.push(
        ...action.payload.things.map((thing, thingIndex) => ({
          id: thing.id,
          elementId: thing.id,
          orderIndex: orderIndexInt + thingIndex,
          type: GuestPhraseOrThingReference.Thing,
        }))
      )

      const newThingsById = action.payload.things.reduce(
        (accumulator: { [key: string]: GuestThingData }, thing) => {
          accumulator[thing.id] = thing
          return accumulator
        },
        {}
      )
      state.thingsById = {
        ...state.thingsById,
        ...newThingsById,
      }
    },
    setLocalThingVisibility: (
      state,
      action: PayloadAction<{
        thingId: string
        isHidden: boolean
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error('setLocalThingVisibility ERROR:', current(thing), action)
        return
      }

      thing.isHidden = action.payload.isHidden
    },
    setLocalThingGroupId: (
      state,
      action: PayloadAction<{
        thingId: string
        groupId: string
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      const newGroup = state.groupsById[action.payload.groupId]

      if (!thing || !newGroup) {
        console.error(
          'setLocalThingGroupId ERROR:',
          current(thing),
          current(newGroup),
          action
        )
        return
      }

      // 1. remove it from the previous group
      const groups = Object.values(state.groupsById)
      let oldGroup: GuestGroupData | undefined = undefined
      let thingIndex: number | undefined = undefined
      for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        const potentialGroup = groups[groupIndex]
        const potentialIndex = potentialGroup.phrasesOrThings.findIndex(
          (phraseOrThing) =>
            phraseOrThing.type === GuestPhraseOrThingReference.Thing &&
            phraseOrThing.elementId === thing.id
        )

        if (potentialIndex >= 0) {
          oldGroup = potentialGroup
          thingIndex = potentialIndex
          break
        }
      }
      if (oldGroup === undefined || thingIndex === undefined) {
        console.error(
          'setLocalThingIndex ERROR 1:',
          current(oldGroup),
          thingIndex,
          action
        )
        return
      }

      const removedThing = oldGroup.phrasesOrThings.splice(thingIndex, 1)
      newGroup.phrasesOrThings.push(removedThing[0])
    },
    setLocalThingIndex: (
      state,
      action: PayloadAction<{
        thingId: string
        newIndex: number
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error('setLocalThingIndex ERROR:', current(thing), action)
        return
      }

      const groups = Object.values(state.groupsById)
      let group: GuestGroupData | undefined = undefined
      let thingIndex: number | undefined = undefined
      for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        const potentialGroup = groups[groupIndex]
        const potentialIndex = potentialGroup.phrasesOrThings.findIndex(
          (phraseOrThing) =>
            phraseOrThing.type === GuestPhraseOrThingReference.Thing &&
            phraseOrThing.elementId === thing.id
        )

        if (potentialIndex >= 0) {
          group = potentialGroup
          thingIndex = potentialIndex
          break
        }
      }

      if (group === undefined || thingIndex === undefined) {
        console.error(
          'setLocalThingIndex ERROR 1:',
          current(group),
          thingIndex,
          action
        )
        return state
      }

      // Remove the thing
      const removedThing = group.phrasesOrThings.splice(thingIndex, 1)
      // Move it to the new position
      group.phrasesOrThings.splice(action.payload.newIndex, 0, removedThing[0])
    },
    setLocalThingImage: (
      state,
      action: PayloadAction<{
        thingId: string
        imageName: string
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error('setLocalThingImage ERROR:', current(thing), action)
        return
      }

      thing.imageName = action.payload.imageName
    },
    setLocalThingOriginalName: (
      state,
      action: PayloadAction<{
        thingId: string
        name: string
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (
        !thing ||
        !thing.currentThingI18n ||
        !thing.currentThingI18n.locale ||
        !thing.originalThingI18n ||
        !thing.originalThingI18n.locale ||
        thing.currentThingI18n.locale !== thing.originalThingI18n.locale
      ) {
        console.error('setLocalThingOriginalName ERROR:', current(thing), action)
        return
      }

      thing.name = action.payload.name
      thing.currentThingI18n.name = action.payload.name
      thing.originalThingI18n.name = action.payload.name
    },
    setLocalThingCurrentI18n: (
      state,
      action: PayloadAction<{
        thingId: string
        currentThingI18n: GuestThingDataI18n
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error('setLocalThingCurrentI18n ERROR:', current(thing), action)
        return
      }

      thing.currentThingI18n = action.payload.currentThingI18n
    },
    setLocalThingCurrentName: (
      state,
      action: PayloadAction<{
        thingId: string
        name: string
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing || !thing.currentThingI18n || !thing.currentThingI18n.locale) {
        console.error('setLocalThingCurrentName ERROR:', current(thing), action)
        return
      }

      thing.currentThingI18n.name = action.payload.name
    },
    setLocalThingDescription: (
      state,
      action: PayloadAction<{
        thingId: string
        description: string
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (
        !thing ||
        !thing.currentThingI18n ||
        !thing.currentThingI18n.locale ||
        !thing.originalThingI18n ||
        !thing.originalThingI18n.locale
      ) {
        console.error('setLocalThingDescription ERROR:', current(thing), action)
        return
      }

      if (thing.currentThingI18n.locale === thing.originalThingI18n.locale) {
        thing.originalThingI18n.description = action.payload.description
      }
      thing.currentThingI18n.description = action.payload.description
    },
    setLocalThingPrices: (
      state,
      action: PayloadAction<{
        thingId: string
        prices: GuestPricePhrase[]
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error('setLocalThingPrices ERROR:', current(state), action)
        return
      }

      thing.prices = action.payload.prices
    },
    setLocalThingAllergenStatus: (
      state,
      action: PayloadAction<{
        thingId: string
        allergenId: string
        allergenStatus: AllergenStatus
      }>
    ) => {
      const allergenId = action.payload.allergenId
      if (!allergensKeys.includes(allergenId)) {
        console.error(
          'setLocalThingAllergenStatus Allergen ERROR:',
          current(state),
          action
        )
        return
      }

      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error(
          'setLocalThingAllergenStatus Thing ERROR:',
          current(state),
          action
        )
        return
      }

      const newAllergensPlus = { ...thing.allergensStatus }
      newAllergensPlus[action.payload.allergenId] = action.payload.allergenStatus
      thing.allergensStatus = newAllergensPlus
    },
    setLocalThingVegetarianDiets: (
      state,
      action: PayloadAction<{
        thingId: string
        vegetarianDiets: string[]
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error(
          'setLocalThingVegetarianDiets ERROR:',
          current(state),
          action
        )
        return
      }

      thing.vegetarianDiets = action.payload.vegetarianDiets
    },
    setLocalThingReligiousDiets: (
      state,
      action: PayloadAction<{
        thingId: string
        religiousDiets: string[]
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error('setLocalThingReligiousDiets ERROR:', current(state), action)
        return
      }

      thing.religiousDiets = action.payload.religiousDiets
    },
    setLocalThingPhrasesOrThings: (
      state,
      action: PayloadAction<{
        thingId: string
        phrasesOrThings: GuestPhraseOrThing[]
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error(
          'setLocalThingPhrasesOrThings ERROR:',
          current(state),
          action
        )
        return
      }

      thing.phrasesOrThings = action.payload.phrasesOrThings
    },
    setLocalThingPhraseId: (
      state,
      action: PayloadAction<{
        thingId: string
        phraseLinkId: string
        phraseId: string
      }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      const phraseIndex =
        !!thing && !!thing.phrasesOrThings
          ? thing.phrasesOrThings.findIndex(
              (phraseOrThing) => phraseOrThing.id === action.payload.phraseLinkId
            )
          : -1
      if (!thing || phraseIndex < 0) {
        console.error(
          'setLocalThingPhraseId ERROR:',
          current(thing),
          phraseIndex,
          action
        )
        return
      }

      thing.phrasesOrThings[phraseIndex].elementId = action.payload.phraseId
    },
    addPhraseOrLinkToThing: (
      state,
      action: PayloadAction<{ thingId: string; phraseOrThing: GuestPhraseOrThing }>
    ) => {
      const thing = state.thingsById[action.payload.thingId]
      if (!thing) {
        console.error('addPhraseOrLinkToThing ERROR:', current(state), action)
        return
      }

      if (!thing.phrasesOrThings || !Array.isArray(thing.phrasesOrThings)) {
        thing.phrasesOrThings = []
      }
      thing.phrasesOrThings.push(action.payload.phraseOrThing)
    },
    deleteLocalThing: (state, action: PayloadAction<string>) => {
      const thingToDelete = state.thingsById[action.payload]
      if (!thingToDelete) {
        console.error('deleteLocalThing ERROR:', current(state), action)
        return
      }

      const filterPhrasesOrThings = (phrasesOrThings: GuestPhraseOrThing[]) =>
        phrasesOrThings.filter(
          (phraseOrThing) =>
            phraseOrThing.type !== GuestPhraseOrThingReference.Thing ||
            phraseOrThing.elementId !== thingToDelete.id
        )

      // 1. Remove it from the group
      const groupToUpdate = Object.values(state.groupsById).find(
        (group) =>
          group.phrasesOrThings.findIndex(
            (phraseOrThing) =>
              phraseOrThing.type === GuestPhraseOrThingReference.Thing &&
              phraseOrThing.elementId === thingToDelete.id
          ) >= 0
      )
      if (!groupToUpdate) {
        console.error('deleteLocalThing group ERROR:', current(state), action)
        return
      }
      groupToUpdate.phrasesOrThings = filterPhrasesOrThings(
        groupToUpdate.phrasesOrThings
      )

      // 2. Remove it from from thingsById & from the other things suggestions
      state.thingsById = Object.values(state.thingsById).reduce(
        (accumulator: { [key: string]: GuestThingData }, thing) => {
          if (thing.id === thingToDelete.id) {
            // Remove it from the thingsById
            return accumulator
          }

          // Remove it from the from the other things suggestions
          accumulator[thing.id] = {
            ...thing,
            phrasesOrThings: filterPhrasesOrThings(thing.phrasesOrThings),
          }

          return accumulator
        },
        {}
      )
    },

    addLocalPhrase: (state, action: PayloadAction<GuestPhraseData>) => {
      state.phrasesById[action.payload.id] = action.payload
    },
    setLocalPhraseOriginalName: (
      state,
      action: PayloadAction<{
        phraseId: string
        phraseName: string
      }>
    ) => {
      const phrase = state.phrasesById[action.payload.phraseId]
      if (
        !phrase ||
        !phrase.currentPhraseI18n ||
        !phrase.currentPhraseI18n.locale ||
        !phrase.originalPhraseI18n ||
        !phrase.originalPhraseI18n.locale ||
        phrase.currentPhraseI18n.locale !== phrase.originalPhraseI18n.locale
      ) {
        console.error('setLocalPhraseOriginalName ERROR:', current(phrase), action)
        return
      }

      phrase.name = action.payload.phraseName
      phrase.originalPhraseI18n.name = action.payload.phraseName
      phrase.currentPhraseI18n.name = action.payload.phraseName
    },
    setLocalPhraseCurrentI18n: (
      state,
      action: PayloadAction<{
        phraseId: string
        currentPhraseI18n: GuestPhraseDataI18n
      }>
    ) => {
      const phrase = state.phrasesById[action.payload.phraseId]
      if (!phrase) {
        console.error('setLocalPhraseCurrentI18n ERROR:', current(state), action)
        return state
      }

      phrase.currentPhraseI18n = action.payload.currentPhraseI18n
    },
    setLocalPhraseCurrentName: (
      state,
      action: PayloadAction<{
        phraseId: string
        phraseName: string
      }>
    ) => {
      const phrase = state.phrasesById[action.payload.phraseId]
      if (!phrase || !phrase.currentPhraseI18n) {
        console.error('setLocalPhraseCurrentName ERROR:', current(phrase), action)
        return state
      }

      phrase.currentPhraseI18n.name = action.payload.phraseName
    },
    deleteLocalPhrase: (state, action: PayloadAction<string>) => {
      const phraseIdToDelete = action.payload

      // Helpers
      const filterPhraseId = (phraseId?: string) =>
        phraseId === phraseIdToDelete ? undefined : phraseId
      const filterPrices = (prices: GuestPricePhrase[]) =>
        prices.map((price) => ({
          ...price,
          phraseId: filterPhraseId(price.phraseId),
        }))
      const filterPhrasesOrThings = (phrasesOrThings: GuestPhraseOrThing[]) =>
        phrasesOrThings.filter(
          (phraseOrThing) =>
            phraseOrThing.type !== GuestPhraseOrThingReference.Phrase ||
            phraseOrThing.elementId !== phraseIdToDelete
        )

      // Filter/Delete
      const updatedPhrasesById = omit(state.phrasesById, [phraseIdToDelete])
      const updatedFooterPricesPhrases = state.footerPricesPhrases?.map(
        (footerPricePhrase) => ({
          ...footerPricePhrase,
          phraseId: filterPhraseId(footerPricePhrase.phraseId),
        })
      )
      const updatedGroupsById = Object.values(state.groupsById).reduce(
        (accumulator: { [key: string]: GuestGroupData }, group) => {
          accumulator[group.id] = {
            ...group,
            prices: filterPrices(group.prices),
            phrasesOrThings: filterPhrasesOrThings(group.phrasesOrThings),
          }

          return accumulator
        },
        {}
      )
      const updatedThingsById = Object.values(state.thingsById).reduce(
        (accumulator: { [key: string]: GuestThingData }, thing) => {
          accumulator[thing.id] = {
            ...thing,
            prices: filterPrices(thing.prices),
            phrasesOrThings: filterPhrasesOrThings(thing.phrasesOrThings),
          }

          return accumulator
        },
        {}
      )

      // Apply
      state.phrasesById = updatedPhrasesById
      state.footerPricesPhrases = updatedFooterPricesPhrases
      state.groupsById = updatedGroupsById
      state.thingsById = updatedThingsById
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, (_) => initialState)
  },
})

export const {
  resetLocalPlace,

  setLocalPlace,
  setLocalPlaceOtherLocales,
  setLocalPlaceImage,
  setLocalPlaceCoordinates,
  setLocalPlaceAddress,
  setLocalPlacePhone,
  setLocalPlaceWhatsapp,
  setLocalPlaceEmail,
  setLocalPlaceWebpage,
  setLocalPlaceInstagram,
  setLocalPlaceFacebook,
  setLocalPlaceTwitter,
  setLocalPlaceCurrentI18n,
  setLocalPlaceCurrentName,
  setLocalPlaceCurrentDescription,
  setLocalPlaceCurrentFooterDescription,
  setLocalPlaceFooterPrices,
  setLocalPlaceUploadedMenus,

  setGroupsIds,
  addLocalGroup,
  addLocalGroupAndThings,
  setLocalGroupVisibility,
  setLocalGroupIndex,
  setLocalGroupImage,
  setLocalGroupOriginalName,
  setLocalGroupCurrentI18n,
  setLocalGroupCurrentName,
  setLocalGroupDescription,
  setLocalGroupPrices,
  setLocalGroupPhrasesOrThings,
  setLocalGroupPhraseId,
  addPhraseOrLinkToGroup,
  deleteLocalGroup,

  addLocalThing,
  addLocalThings,
  setLocalThingVisibility,
  setLocalThingGroupId,
  setLocalThingIndex,
  setLocalThingImage,
  setLocalThingOriginalName,
  setLocalThingCurrentI18n,
  setLocalThingCurrentName,
  setLocalThingDescription,
  setLocalThingPrices,
  setLocalThingAllergenStatus,
  setLocalThingVegetarianDiets,
  setLocalThingReligiousDiets,
  setLocalThingPhrasesOrThings,
  setLocalThingPhraseId,
  addPhraseOrLinkToThing,
  deleteLocalThing,

  addLocalPhrase,
  setLocalPhraseOriginalName,
  setLocalPhraseCurrentI18n,
  setLocalPhraseCurrentName,
  deleteLocalPhrase,
} = localPlaceSlice.actions
