import React from 'react'
import styled from 'styled-components'
// Libs
import { HEADER_HEIGHT, SECTIONS_MARGIN } from '../../../lib/theme'
// Components
import { BigSpinner } from '../../molecules/BigSpinner'

const Container = styled.div`
  height: 100%;
  padding-top: ${({ pretendHeader }: { pretendHeader?: boolean }) =>
    pretendHeader ? `${HEADER_HEIGHT}` : '0'}px;
`
const StyledLoader = styled(BigSpinner)`
  margin-top: ${SECTIONS_MARGIN}px;
`

export const FullscreenLoader = ({
  pretendHeader,
}: {
  pretendHeader?: boolean
}) => (
  <Container pretendHeader={pretendHeader}>
    <StyledLoader />
  </Container>
)
