import { AllergenStatus } from '../constants/allergens'

export type GuestLocale = {
  language: string
  country?: string
  locale: string
}

export type Coordinates = {
  latitude: number
  longitude: number
}

export type GuestPlaceData = {
  id: string
  name: string
  urlName: string
  locale: string
  otherLocales: string[]
  country: string
  currency: string
  imageName?: string
  uploadedMenus?: string[]
  coordinates: Coordinates
  address?: string
  phone?: string
  whatsapp?: string
  email?: string
  webpage?: string
  instagram?: string
  facebook?: string
  twitter?: string

  originalPlaceI18n?: GuestPlaceDataI18n
  currentPlaceI18n?: GuestPlaceDataI18n
  footerPricesPhrases?: GuestPlacePricePhrase[]

  groupsIds: string[]
  groupsById: { [key: string]: GuestGroupData }
  phrasesById: { [key: string]: GuestPhraseData }
  thingsById: { [key: string]: GuestThingData }

  isPro: boolean
}
export type GuestPlaceDataI18n = {
  id: string
  locale: string
  name?: string
  description?: string
  footerDescription?: string
}
export type GuestPlacePricePhrase = {
  id: string
  orderIndex: number
  price?: string
  phraseId?: string
}

export type GuestPhraseData = {
  id: string
  name: string

  originalPhraseI18n?: GuestPhraseDataI18n
  currentPhraseI18n?: GuestPhraseDataI18n
}
export type GuestPhraseDataI18n = {
  id: string
  locale: string
  name?: string
}

export type GuestPricePhrase = {
  id: string
  orderIndex: number
  price?: string
  phraseId?: string
}

export enum GuestPhraseOrThingReference {
  Thing = 'Thing',
  Phrase = 'Phrase',
}
export type GuestPhraseOrThing = {
  id: string
  type: GuestPhraseOrThingReference
  orderIndex: number
  elementId: string
}

export type GuestGroupData = {
  id: string
  isHidden: boolean
  name: string
  imageName?: string

  originalGroupI18n?: GuestGroupDataI18n
  currentGroupI18n?: GuestGroupDataI18n

  prices: GuestPricePhrase[]
  phrasesOrThings: GuestPhraseOrThing[]
}
export type GuestGroupDataI18n = {
  id: string
  locale: string
  name?: string
  description?: string
}

export type GuestThingData = {
  id: string
  isHidden: boolean
  name: string
  groupName: string
  imageName?: string

  originalThingI18n?: GuestThingDataI18n
  currentThingI18n?: GuestThingDataI18n

  price?: string
  prices: GuestPricePhrase[]
  phrasesOrThings: GuestPhraseOrThing[]
} & GuestThingAllergensEtAll
export type GuestThingDataI18n = {
  id: string
  locale: string
  name?: string
  description?: string
}
export type GuestThingAllergensEtAll = {
  allergensStatus: AllergenStatusByKey
  vegetarianDiets: string[]
  religiousDiets: string[]
}
export type AllergenStatusByKey = { [key: string]: AllergenStatus }

export type GuestSavedPlace = {
  updatedAt: number

  id: string
  currency: string
  country: string
  name: string
  imageName?: string

  savedThings: { [key: string]: GuestSavedThing }
}

export type GuestSavedThing = {
  updatedAt: number

  id: string
  groupName: string
  originalName: string
  currentName: string
  imageName?: string
  price?: string
  pricePhraseId?: string
}

export type PhraseAndThingData = {
  id: string
  phraseLabel?: string
  thingData?: GuestThingData
}
