import {
  configureStore,
  getDefaultMiddleware,
  PayloadAction,
} from '@reduxjs/toolkit'
// import { createLogger } from 'redux-logger'
// Libs
import { VegetarianDiet, ReligiousDiet, Allergen } from '../lib/constants'
import {
  getGuestLocalItem,
  saveGuestLocalItem,
  STORAGE_KEY,
} from '../lib/localStorage'
import { GuestSavedPlace } from '../lib/types'
import {
  addSavedPlace,
  removeSavedPlace,
  resetSaved,
  toggleSavedThing,
} from './slices'
// Redux
import {
  setAllergensFilter,
  setReligiousDietFilter,
  setVegetarianDietFilter,
} from './slices/guestFiltersSlice'
import { rootReducer, RootState } from './slices/rootReducer'

const getPreloadState = (): any => {
  let storedAllergens = getGuestLocalItem(STORAGE_KEY.ALLERGENS)
  let storedVegetarianDiet = getGuestLocalItem(STORAGE_KEY.VEGETARIAN_DIET)
  let storedReligiousDiet = getGuestLocalItem(STORAGE_KEY.RELIGIOUS_DIET)
  let storedSavedPlaces = getGuestLocalItem(STORAGE_KEY.SAVED_PLACES)

  if (!storedAllergens) {
    storedAllergens = JSON.stringify([])
    saveGuestLocalItem(STORAGE_KEY.ALLERGENS, storedAllergens)
  }
  if (!storedVegetarianDiet) {
    storedVegetarianDiet = VegetarianDiet.NONE
    saveGuestLocalItem(STORAGE_KEY.VEGETARIAN_DIET, storedVegetarianDiet)
  }
  if (!storedReligiousDiet) {
    storedReligiousDiet = ReligiousDiet.NONE
    saveGuestLocalItem(STORAGE_KEY.RELIGIOUS_DIET, storedReligiousDiet)
  }
  if (!storedSavedPlaces) {
    storedSavedPlaces = JSON.stringify({})
    saveGuestLocalItem(STORAGE_KEY.SAVED_PLACES, storedSavedPlaces)
  }

  const allergensFilter: Allergen[] = JSON.parse(storedAllergens)
  const vegetarianDietFilter: VegetarianDiet = storedVegetarianDiet as VegetarianDiet
  const religiousDietFilter: ReligiousDiet = storedReligiousDiet as ReligiousDiet
  const savedPlaces: { [key: string]: GuestSavedPlace } = JSON.parse(
    storedSavedPlaces
  )

  return {
    guestFilters: {
      areOpen: false,
      allergens: allergensFilter,
      vegetarianDiet: vegetarianDietFilter,
      religiousDiet: religiousDietFilter,
    },
    guestSaved: savedPlaces,
  }
}

const localStorageMiddleware = ({ getState }: { getState: () => RootState }) => (
  next: any
) => async (action: PayloadAction<any>) => {
  // Update Saved Places/Things
  if (
    action.type === resetSaved.type ||
    action.type === addSavedPlace.type ||
    action.type === removeSavedPlace.type ||
    action.type === toggleSavedThing.type
  ) {
    const nextAction = next(action)

    const updatedState = getState()
    const storedSavedPlaces = JSON.stringify(updatedState.guestSaved)
    saveGuestLocalItem(STORAGE_KEY.SAVED_PLACES, storedSavedPlaces)

    return nextAction
  }

  // Update Filters
  if (action.type === setAllergensFilter.type) {
    saveGuestLocalItem(STORAGE_KEY.ALLERGENS, JSON.stringify(action.payload))
  } else if (action.type === setVegetarianDietFilter.type) {
    saveGuestLocalItem(STORAGE_KEY.VEGETARIAN_DIET, action.payload)
  } else if (action.type === setReligiousDietFilter.type) {
    saveGuestLocalItem(STORAGE_KEY.RELIGIOUS_DIET, action.payload)
  }

  return next(action)
}

export const setupStore = () => {
  const preloadedState = getPreloadState()

  const middlewares: any = [...getDefaultMiddleware(), localStorageMiddleware]
  // if (process.env.NODE_ENV !== 'production') {
  //   middlewares.push(
  //     createLogger({
  //       duration: true,
  //     })
  //   )
  // }

  const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
    preloadedState,
    enhancers: [],
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./slices/rootReducer', () =>
      store.replaceReducer(rootReducer)
    )
  }

  return store
}
