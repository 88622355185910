import { DEFAULT_THEME } from '@zendeskgarden/react-theming'
import { DefaultToastOptions } from 'react-hot-toast/dist/core/types'

// COLORS (COLOURS?)
export const DARK_COLOR = '#222222'
export const MID_DARK_COLOR = '#303030'
const DARK_GREY_COLOR = '#6b6b6b'
const VERY_LIGHT_GREY_COLOR = '#a1a1a1'
export const LIGHT_GREY_COLOR = '#d8d8d8'
const LIGHT_COLOR = '#fefefe'

export const TRANSPARENT_COLOR = 'rgba(0, 0, 0, 0)'
export const LIGHT_SEMI_TRANSPARENT_COLOR = 'rgba(255, 255, 255, 0.89)'
export const DARK_SEMI_TRANSPARENT_COLOR = 'rgba(0, 0, 0, 0.24)'

// GRID ITEMS
export const GRID_ITEM_SHADOW = `box-shadow: 0 0px 13px ${DARK_SEMI_TRANSPARENT_COLOR};`

// PRIMARY COLOR
export const PRIMARY_COLOR_BASE = '008228'
export const PRIMARY_COLOR = `#${PRIMARY_COLOR_BASE}`
const PRIMARY_COLOR_DARK = '#00d040'
const PRIMARY_COLOR_RGB = '0, 130, 40'
export const primaryColorWithAlpha = (alpha: number) =>
  `rgba(${PRIMARY_COLOR_RGB}, ${alpha})`
export const LIGHT_PRIMARY_COLOR = '#00AF34'

export const DANGER_COLOR = `#b00000`

// TRANSITIONS
// export const COLORS_TRANSITIONS =
// 'transition: border-color 0.25s ease-in-out, box-shadow 0.1s ease-in-out, background-color 0.25s ease-in-out, color 0.25s ease-in-out;'

// FONTS
export enum FontSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
}

export const FONT_SIZE_SMALL = 14 //12px;
const LINE_HEIGHT_SMALL = 16 //22 //16px;
export const SMALL_FONT = `
  font-size: ${FONT_SIZE_SMALL}px;
  line-height: ${LINE_HEIGHT_SMALL}px;
`

const FONT_SIZE_MEDIUM = 16 //14px;
const LINE_HEIGHT_MEDIUM = 18 //24 //20px;
export const MEDIUM_FONT = `
  font-size: ${FONT_SIZE_MEDIUM}px;
  line-height: ${LINE_HEIGHT_MEDIUM}px;
`

export const FONT_SIZE_LARGE = 20 //18px
const LINE_HEIGHT_LARGE = 22 //30 // 24px;
export const LARGE_FONT = `
  font-size: ${FONT_SIZE_LARGE}px;
  line-height: ${LINE_HEIGHT_LARGE}px;
`

export const FONT_SIZE_X_LARGE = 26 //22px;
const LINE_HEIGHT_X_LARGE = 28 //28px;
export const X_LARGE_FONT = `
  font-size: ${FONT_SIZE_X_LARGE}px;
  line-height: ${LINE_HEIGHT_X_LARGE}px;
`

const FONT_SIZE_XX_LARGE = 32 //26px;
const LINE_HEIGHT_XX_LARGE = 34 //32px;
// const XX_LARGE_FONT = `
//   font-size: ${FONT_SIZE_XX_LARGE}px;
//   line-height: ${LINE_HEIGHT_XX_LARGE}px;
// `

const FONT_SIZE_XXX_LARGE = 38 // 36px;
const LINE_HEIGHT_XXX_LARGE = 40 // 44px;

export const FONT_SIZE_MENU2 = 48
export const LINE_HEIGHT_MENU2 = 54

export const SYSTEM_FONT =
  'Rubik,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",Arial,sans-serif'
export const PATTAYA_FONT = `Pattaya,serif`
export const MONO_FONT =
  'SFMono-Regular,Consolas,"Liberation Mono",Menlo,Courier,monospace'

export const MENUXMENU_THEME_BRIGHT = {
  ...DEFAULT_THEME,
  fonts: {
    mono: MONO_FONT,
    system: SYSTEM_FONT,
  },
  fontSizes: {
    xs: '12px',
    sm: `${FONT_SIZE_SMALL}px`,
    md: `${FONT_SIZE_MEDIUM}px`,
    lg: `${FONT_SIZE_LARGE}px`,
    xl: `${FONT_SIZE_X_LARGE}px`,
    xxl: `${FONT_SIZE_XX_LARGE}px`,
    xxxl: `${FONT_SIZE_XXX_LARGE}px`,
  },
  lineHeights: {
    sm: `${LINE_HEIGHT_SMALL}px`,
    md: `${LINE_HEIGHT_MEDIUM}px`,
    lg: `${LINE_HEIGHT_LARGE}px`,
    xl: `${LINE_HEIGHT_X_LARGE}px`,
    xxl: `${LINE_HEIGHT_XX_LARGE}px`,
    xxxl: `${LINE_HEIGHT_XXX_LARGE}px`,
  },
  // space: {
  //   base: 4,
  //   xxs: '4px',
  //   xs: '8px',
  //   sm: '12px',
  //   md: '20px',
  //   lg: '32px',
  //   xl: '40px',
  //   xxl: '48px',
  // },
  colors: {
    // @ts-ignore
    ...DEFAULT_THEME.colors,
    base: 'light',
    background: LIGHT_COLOR,
    midBackground: LIGHT_GREY_COLOR,
    lightForeground: DARK_GREY_COLOR,
    midForeground: MID_DARK_COLOR,
    foreground: DARK_COLOR,

    headerHue: LIGHT_SEMI_TRANSPARENT_COLOR,
    chromeHue: '#f9f9f9',
    chromeText: 'black',
    gridItemHue: '#fafafa',
    gridBorderHue: '#cfcfcf',

    primaryHue: PRIMARY_COLOR,
    successHue: PRIMARY_COLOR,
    dangerHue: DANGER_COLOR,
    warningHue: 'yellow',
    neutralHue: '#708090',
    mapFilter: 'none',
  },
}
export const MENUXMENU_THEME_DARK = {
  ...MENUXMENU_THEME_BRIGHT,
  colors: {
    ...MENUXMENU_THEME_BRIGHT.colors,
    base: 'dark',

    background: DARK_COLOR,
    midBackground: MID_DARK_COLOR,
    lightForeground: VERY_LIGHT_GREY_COLOR,
    midForeground: VERY_LIGHT_GREY_COLOR,
    foreground: LIGHT_GREY_COLOR,

    headerHue: DARK_SEMI_TRANSPARENT_COLOR,
    chromeHue: DARK_COLOR,
    chromeText: VERY_LIGHT_GREY_COLOR,
    gridItemHue: DARK_COLOR,
    gridBorderHue: DARK_GREY_COLOR,

    primaryHue: PRIMARY_COLOR_DARK,
    successHue: PRIMARY_COLOR_DARK,
    neutralHue: '#808F9D',
    mapFilter: 'invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%)',
  },
}

export const TOASTER_OPTIONS_BRIGHT: DefaultToastOptions = {
  duration: 3000,
  position: 'top-center',
  style: {
    backgroundColor: LIGHT_COLOR,
    color: DARK_COLOR,
    borderRadius: '4px',
    fontFamily: SYSTEM_FONT,
    fontSize: FONT_SIZE_LARGE,
  },
  success: {
    iconTheme: {
      primary: PRIMARY_COLOR,
      secondary: LIGHT_COLOR,
    },
  },
  error: {
    iconTheme: {
      primary: DANGER_COLOR,
      secondary: LIGHT_COLOR,
    },
  },
}
export const TOASTER_OPTIONS_DARK = {
  ...TOASTER_OPTIONS_BRIGHT,
  style: {
    ...TOASTER_OPTIONS_BRIGHT.style,
    backgroundColor: DARK_COLOR,
    color: LIGHT_GREY_COLOR,
  },
  success: {
    iconTheme: {
      primary: LIGHT_PRIMARY_COLOR,
      secondary: DARK_COLOR,
    },
  },
}

export const HEADER_HEIGHT = 52

export const DROPDOWN_MAX_HEIGHT = '302px'
export const DROPDOWN_POPPER_MODIFIERS: any = {
  escapeWithReference: {
    enabled: true,
  },
  preventOverflow: {
    enabled: false,
  },
  hide: {
    enabled: false,
  },
  keepTogether: {
    enabled: true,
  },
}

// SIZES (Prime number FTW :P)
export const SPINNER_SIZE = 149

export const MOBILE_WIDTH = 845
export const SMALL_WIDTH = 547

export const MAX_WIDTH = 997
export const MAX_SIDEBAR_WIDTH = 257
export const SMALL_SIDEBAR_WIDTH = 59

// GUEST
export const MAX_PLACE_HEADER_WIDTH = 797
export const MIN_PLACE_GRID_WIDTH_DESKTOP = 420
export const MIN_PLACE_GRID_WIDTH_MOBILE = 288 // To fit in a iPhoneSE screen...
export const MIN_PLACE_CONTENT_GRID_WIDTH = 283
export const MIN_GROUP_GRID_WIDTH = 269
export const MIN_THING_GRID_WIDTH = 256

export const SECTIONS_MARGIN = 61

// MEDIA QUERIES
export const MOBILE_MEDIA_QUERY = window.matchMedia(
  `screen and (max-width: ${MOBILE_WIDTH}px)`
)
export const SMALL_MEDIA_QUERY = window.matchMedia(
  `screen and (max-width: ${SMALL_WIDTH}px)`
)
