const COMMON_PREFIX = 'menuxmenu_common_'
const GUEST_PREFIX = 'menuxmenu_guest_'

const COOKIES_VERSION = '1.0.1'

export enum STORAGE_KEY {
  COOKIES_ACCEPTED = 'COOKIES_ACCEPTED',

  ALLERGENS = 'ALLERGENS',
  VEGETARIAN_DIET = 'VEGETARIAN_DIET',
  RELIGIOUS_DIET = 'RELIGIOUS_DIET',

  SAVED_PLACES = 'SAVED_PLACES',
}

const getLocalItem = (key: string) => localStorage.getItem(key)
const saveLocalItem = (key: string, item: string) =>
  localStorage.setItem(key, item)

export const getCommonLocalItem = (key: STORAGE_KEY) =>
  getLocalItem(`${COMMON_PREFIX}${key}`)
export const saveCommonLocalItem = (key: STORAGE_KEY, item: string) =>
  saveLocalItem(`${COMMON_PREFIX}${key}`, item)
export const getGuestLocalItem = (key: STORAGE_KEY) =>
  getLocalItem(`${GUEST_PREFIX}${key}`)
export const saveGuestLocalItem = (key: STORAGE_KEY, item: string) =>
  saveLocalItem(`${GUEST_PREFIX}${key}`, item)

const isSemverUpToDate = (savedSemver: string, latestSemver: string): boolean => {
  if (!savedSemver) {
    return false
  }

  const acceptedSemverArray = savedSemver.split('.')
  if (acceptedSemverArray.length !== 3) {
    // Not a semver string
    return false
  }

  const latestSemverArray = latestSemver.split('.')
  for (let i = 0; i < 3; i++) {
    if (latestSemverArray[i] > acceptedSemverArray[i]) {
      return false
    }
  }

  return true
}

export const areLocalCookiesAccepted = (): boolean => {
  const versionAccepted = getCommonLocalItem(STORAGE_KEY.COOKIES_ACCEPTED)
  if (!versionAccepted) {
    return false
  }

  return isSemverUpToDate(versionAccepted, COOKIES_VERSION)
}
export const setLocalCookiesAccepted = () =>
  saveCommonLocalItem(STORAGE_KEY.COOKIES_ACCEPTED, COOKIES_VERSION)
