import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'
// Libs
import {
  GuestPlaceData,
  GuestSavedPlace,
  GuestSavedThing,
  GuestThingData,
} from '../../../lib/types'

const initialState = {} as { [key: string]: GuestSavedPlace }

const getSavedPlaceFromPlaceData = (
  placeData: GuestPlaceData,
  updatedAt: number = Date.now(),
  savedThings?: { [key: string]: GuestSavedThing }
): GuestSavedPlace => ({
  updatedAt,

  id: placeData.id,
  name: placeData.name,
  country: placeData.country,
  currency: placeData.currency,
  imageName: placeData.imageName,

  savedThings: !!savedThings ? savedThings : {},
})

export const guestSavedSlice = createSlice({
  name: 'guestSaved',
  initialState,
  reducers: {
    resetSaved: () => initialState,

    addSavedPlace: (state, action: PayloadAction<GuestPlaceData>) => ({
      ...state,
      [action.payload.id]: getSavedPlaceFromPlaceData(action.payload),
    }),
    removeSavedPlace: (state, action: PayloadAction<string>) =>
      omit(state, [action.payload]),

    toggleSavedThing: (
      state,
      action: PayloadAction<{
        place: GuestPlaceData
        thing: GuestThingData
      }>
    ) => {
      const placeId = action.payload.place.id
      const thingId = action.payload.thing.id

      // 0. is place/thing saved?
      const isPlaceSaved = !!state[placeId]
      const isThingSaved = isPlaceSaved && !!state[placeId].savedThings[thingId]

      if (isThingSaved) {
        // Remove the thing

        const updatedSavedThings = omit(state[placeId].savedThings, [thingId])

        return {
          ...state,
          [placeId]: {
            ...state[placeId],
            savedThings: updatedSavedThings,
          },
        }
      } else {
        // Add the thing
        const newThing = action.payload.thing
        const updatedAt = Date.now()

        const newSavedThing: GuestSavedThing = {
          updatedAt,

          id: newThing.id,
          groupName: newThing.groupName,
          originalName: newThing.name,
          currentName: newThing.currentThingI18n?.name || newThing.name,
          price: newThing.price,
        }

        if (!isPlaceSaved) {
          // Add the place
          const newPlace = action.payload.place

          const newSavedPlace: GuestSavedPlace = getSavedPlaceFromPlaceData(
            newPlace,
            updatedAt,
            {
              [thingId]: newSavedThing,
            }
          )

          return {
            ...state,
            [newPlace.id]: newSavedPlace,
          }
        }

        return {
          ...state,
          [placeId]: {
            ...state[placeId],
            updatedAt,
            savedThings: {
              ...state[placeId].savedThings,
              [thingId]: newSavedThing,
            },
          },
        }
      }
    },
  },
})

export const {
  resetSaved,

  addSavedPlace,
  removeSavedPlace,

  toggleSavedThing,
} = guestSavedSlice.actions
