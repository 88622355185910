// ISO 639-1 [https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes]
// Total: 104
export const LANGUAGES: { [key: string]: string } = {
  af: 'Afrikaans (Afrikaans)',
  am: 'አማርኛ (Amharic)',
  ar: 'العربية (Arabic)',
  as: 'অসমীয়া (Assamese)',
  az: 'Azərbaycanca (Azerbaijani)',
  be: 'Беларуская (Belarusian)',
  bg: 'български (Bulgarian)',
  bn: 'বাংলা (Bengali)',
  bo: 'བོད་ཡིག (Tibetan)',
  bs: 'Bosanski (Bosnian)',
  ca: 'Català (Catalan)',
  ceb: 'Sebwano (Cebuano)',
  chr: 'ᏣᎳᎩ ᎦᏬᏂᎯᏍᏗ (Cherokee)',
  co: 'Corsu (Corsican)',
  cs: 'Češki (Czech)',
  cy: 'Cymraeg (Welsh)',
  da: 'Dansk (Danish)',
  de: 'Deutsch (German)',
  el: 'ελληνικά (Greek)',
  en: 'English (English)',
  eo: 'Esperanto (Esperanto)',
  es: 'Español (Spanish)',
  et: 'Eesti keel (Estonian)',
  eu: 'Euskara (Basque)',
  fa: 'فارسی (Persian)',
  fi: 'Suomen Kieli (Finnish)',
  fr: 'Français (French)',
  fy: 'Frysk (Frisian)',
  ga: 'Gaeilge (Irish)',
  gd: 'Gàidhlig (Scottish Gaelic)',
  gl: 'Galego (Galician)',
  gu: 'ગુજરાતી (Gujarati)',
  ha: 'هَوُسَ (Hausa)',
  // haw: 'Ōlelo Hawaiʻi (Hawaiian)',
  he: 'עברית (Hebrew)',
  hi: 'हिन्दी (Hindi)',
  // hmn: 'Hmoob (Hmong)',
  hr: 'Hrvatski (Croatian)',
  ht: 'Kreyòl Ayisyen (Haitian Creole)',
  hu: 'Magyar (Hungarian)',
  hy: 'հայերեն (Armenian)',
  id: 'Bahasa Indonesia (Indonesian)',
  ig: 'Asụ̀sụ̀ Ìgbò (Igbo)',
  is: 'Íslenska (Icelandic)',
  it: 'Italiano (Italian)',
  ja: '日本語 (Japanese)',
  jv: 'Jawa (Javanese)',
  ka: 'ქართული (Georgian)',
  kk: 'Қазақша (Kazakh)',
  km: 'ភាសាខ្មែរ (Khmer)',
  kn: 'ಕನ್ನಡ (Kannada)',
  ko: '한국어 (Korean)',
  ku: 'Kurdî (Kurdish)',
  ky: 'Кыргызча (Kyrgyz)',
  la: 'Latīna (Latin)',
  lb: 'Lëtzebuergesch (Luxembourgish)',
  lo: 'ພາສາລາວ (Lao)',
  lt: 'Lietuvių (Lithuanian)',
  lv: 'Latviešu (Latvian)',
  mg: 'Malagasy (Malagasy)',
  mi: 'Māori (Maori)',
  mk: 'Македонски (Macedonian)',
  ml: 'മലയാളം (Malayalam)',
  mn: 'Монгол хэл дээр (Mongolian)',
  mr: 'मराठी (Marathi)',
  ms: 'Melayu (Malay)',
  mt: 'Malti (Maltese)',
  my: 'မြန်မာ (Myanmar)',
  ne: 'नेपाली (Nepali)',
  nl: 'Nederlands (Dutch)',
  no: 'Norsk (Norwegian)',
  ny: 'Chinyanja (Chichewa)',
  pa: 'ਪੰਜਾਬੀ (Punjabi)',
  pl: 'Polski (Polish)',
  ps: 'پښتو (Pashto)',
  pt: 'Português (Portuguese)',
  ro: 'Română (Romanian)',
  ru: 'Pусский (Russian)',
  sd: 'سنڌي (Sindhi)',
  si: 'සිංහල (Sinhala)',
  sk: 'Slovenský (Slovak)',
  sl: 'Slovenščina (Slovene)',
  sm: 'Gagana faʻa Sāmoa (Samoan)',
  sq: 'Shqip (Albanian)',
  sr: 'Српски (Serbian)',
  st: 'Sesotho (Sotho)',
  sn: 'chiShona (Shona)',
  so: 'Af-Soomaali (Somali)',
  su: 'Basa Sunda (Sudanese)',
  sv: 'Svenska (Swedish)',
  sw: 'Kiswahili (Swahili)',
  ta: 'தமிழ் (Tamil)',
  te: 'తెలుగు (Telugu)',
  tg: 'Тоҷикӣ (Tajik)',
  th: 'ไทย (Thai)',
  tl: 'Wikang Filipino (Filipino)',
  tr: 'Türkçe (Turkish)',
  ur: 'اُردُو (Urdu)',
  uk: 'Українська (Ukrainian)',
  uz: 'Oʻzbekcha (Uzbek)',
  vi: 'Tiếng Việt (Vietnamese)',
  xh: 'isiXhosa (Xhosa)',
  yi: 'ייִדיש‎ (Yiddish)',
  yo: 'Yorùbá (Yoruba)',
  'zh-Hans': '汉语 (Chinese Simplified)',
  'zh-Hant': '漢語 (Chinese Traditional)',
  zu: 'isiZulu (Zulu)',
}

export const LANGUAGE_KEYS = Object.keys(LANGUAGES).sort(
  (keyA: string, keyB: string) => {
    const valueA = LANGUAGES[keyA]
    const valueB = LANGUAGES[keyB]

    if (valueA > valueB) {
      return 1
    }
    if (valueA < valueB) {
      return -1
    }

    return 0
  }
)

// lvl.0
//   'en',
//   'it',
//   'es',

// lvl.1
//   'ca',
//   'de',
//   'fr',

// lvl.2
//   'ar',
//   'ko',
//   'pt',
//   'ru',

// lvl.3
//   'he',
//   'ja',
//   'zh-hans',
//   'zh-hant',

export const SUPPORTED_GUEST_APP_LANGUAGES = [
  // 'ar',
  'ca',
  'de',
  'en',
  'es',
  'fr',
  //   'he',
  'it',
  //   'ja',
  //   'ko',
  //   'pt',
  //   'ru',
  //   'zh-hans',
  //   'zh-hant',
]

// Used by non core pages (legal, about, etc.)
export const SUPPORTED_GUEST_APP_EXTRA_LANGUAGES = ['ca', 'en', 'es', 'it']

export const SUPPORTED_HOST_APP_LANGUAGES = [
  // 'ca',
  'en',
  'es',
  'it',
]

// We try to remove the '-XX' of Alpha-2 country code
const removeCountryCode = (locale: string) => locale.slice(0, -3)

export const getSupportedPlaceLanguage = (
  placeLanguages: string[],
  languagesOrLocales: string[],
  currentLanguage?: string
): string => {
  if (!currentLanguage) {
    return placeLanguages[0]
  }

  if (placeLanguages.includes(currentLanguage)) {
    return currentLanguage
  }

  // We try to fallback to a user selected language
  for (let i = 0; i < languagesOrLocales.length; i += 1) {
    const languageOrLocale = languagesOrLocales[i]

    if (placeLanguages.includes(languageOrLocale)) {
      return languageOrLocale
    }
  }

  // Fallback to "default" place language
  return placeLanguages[0]
}

const getSupportedAppLanguage = (
  languagesOrLocales: string[],
  supportedLanguages: string[]
): string => {
  for (let i = 0; i < languagesOrLocales.length; i += 1) {
    const languageOrLocale = languagesOrLocales[i]

    if (supportedLanguages.includes(languageOrLocale)) {
      return languageOrLocale
    }

    const potentialLanguage = removeCountryCode(languageOrLocale)
    if (supportedLanguages.includes(potentialLanguage)) {
      return potentialLanguage
    }
  }

  // Fallback to "default" app language
  return 'en'
}

export const getSupportedGuestAppLanguage = (
  languagesOrLocales: string[]
): string =>
  getSupportedAppLanguage(languagesOrLocales, SUPPORTED_GUEST_APP_LANGUAGES)

export const getSupportedGuestAppExtraLanguage = (
  languagesOrLocales: string[]
): string =>
  getSupportedAppLanguage(languagesOrLocales, SUPPORTED_GUEST_APP_EXTRA_LANGUAGES)

export const getSupportedHostAppLanguage = (
  languagesOrLocales: string[]
): string =>
  getSupportedAppLanguage(languagesOrLocales, SUPPORTED_HOST_APP_LANGUAGES)

// TODO: all https://developers.facebook.com/docs/messenger-platform/messenger-profile/supported-locales/
const FACEBOOK_LOCALES_FROM_LANGUAGE: { [key: string]: string } = {
  ca: 'ca_ES',
  de: 'de_DE',
  en: 'en_GB',
  es: 'es_ES',
  fr: 'fr_FR',
  it: 'it_IT',
}

export const getFacebookLocaleFromLanguage = (language: string): string => {
  const facebookLocale = FACEBOOK_LOCALES_FROM_LANGUAGE[language]

  if (!!facebookLocale) {
    return facebookLocale
  }

  return FACEBOOK_LOCALES_FROM_LANGUAGE['en']
}

// Source for Tesseract language files: https://github.com/tesseract-ocr/tessdata
export const TESSERACT_LANGUAGE: { [key: string]: string } = {
  af: 'afr',
  am: 'amh',
  ar: 'ara',
  as: 'asm',
  az: 'aze+aze_cyrl', // NOTE: 2 languages
  be: 'bel',
  bg: 'bul',
  bn: 'ben',
  bo: 'bod',
  bs: 'bos',
  ca: 'cat',
  ceb: 'ceb',
  chr: 'chr',
  co: 'cos',
  cs: 'ces',
  cy: 'cym',
  da: 'dan',
  de: 'deu',
  el: 'ell',
  en: 'eng',
  eo: 'epo',
  es: 'spa',
  et: 'est',
  eu: 'eus',
  fa: 'fas',
  fi: 'fin',
  fr: 'fra',
  fy: 'fry',
  ga: 'gle',
  gd: 'gla',
  gl: 'glg',
  gu: 'guj',
  // ha: 'hau', // NOTE: does not exist...
  // haw: 'Ōlelo Hawaiʻi (Hawaiian)',
  he: 'heb',
  hi: 'hin',
  hr: 'hrv',
  ht: 'hat',
  hu: 'hun',
  // hmn: 'Hmoob (Hmong)',
  hy: 'hye',
  id: 'ind',
  // ig: 'ibo', // NOTE: does not exist...
  is: 'isl',
  it: 'ita',
  ja: 'jpn',
  jv: 'jav',
  ka: 'kat',
  kk: 'kaz',
  km: 'khm',
  kn: 'kan',
  ko: 'kor',
  // ku: 'kur', // NOTE: does not exist...
  ky: 'kir',
  la: 'lat',
  lb: 'ltz',
  lo: 'lao',
  lt: 'lit',
  lv: 'lav',
  // mg: 'mlg', // NOTE: does not exist...
  mi: 'mri',
  mk: 'mkd',
  ml: 'mal',
  mn: 'mon',
  mr: 'mar',
  ms: 'msa',
  mt: 'mlt',
  my: 'mya',
  ne: 'nep',
  nl: 'nld',
  no: 'nor',
  // ny: 'nya', // NOTE: does not exist...
  ps: 'pus',
  pl: 'pol',
  pt: 'por',
  pa: 'pan',
  ro: 'ron',
  ru: 'rus',
  sd: 'snd',
  si: 'sin',
  sk: 'slk',
  sl: 'slv',
  // sm: 'smo', // NOTE: does not exist...
  sq: 'sqi',
  sr: 'srp+srp_latn', // NOTE: 2 languages
  // st: 'sot', // NOTE: does not exist...
  // sn: 'sna', // NOTE: does not exist...
  // so: 'som', // NOTE: does not exist...
  su: 'sun',
  sv: 'swe',
  sw: 'swa',
  ta: 'tam',
  te: 'tel',
  tg: 'tgk',
  th: 'tha',
  tl: 'tgl',
  tr: 'tur',
  ur: 'urd',
  uk: 'ukr',
  uz: 'uzb+uzb_cyrl', // NOTE: 2 languages
  vi: 'vie',
  // xh: 'xho', // NOTE: does not exist...
  yi: 'yid',
  yo: 'yor',
  'zh-Hans': 'chi_sim+chi_sim_vert', // NOTE: 2 languages
  'zh-Hant': 'chi_tra+chi_tra_vert', // NOTE: 2 languages
  // zu: 'zul', // NOTE: does not exist...
}
