import { I18nExtendedElement } from '../types'

export enum VegetarianDiet {
  VEGAN = 'VEGAN',
  LACTO_VEGETARIAN = 'LACTO_VEGETARIAN',
  OVO_VEGETARIAN = 'OVO_VEGETARIAN',
  LACTO_OVO_VEGETARIAN = 'LACTO_OVO_VEGETARIAN',
  NONE = 'NONE',
}

export const vegetarianDietsKeys = Object.keys(VegetarianDiet)

// I18N
const i18nLabelMappings: { [key: string]: string } = {
  [VegetarianDiet.VEGAN]: 'core:diet.vegan',
  [VegetarianDiet.LACTO_VEGETARIAN]: 'core:diet.lactoVegetarian',
  [VegetarianDiet.OVO_VEGETARIAN]: 'core:diet.ovoVegetarian',
  [VegetarianDiet.LACTO_OVO_VEGETARIAN]: 'core:diet.lactoOvoVegetarian',
  [VegetarianDiet.NONE]: 'core:diet.none',
}

const i18nHintMappings: { [key: string]: string } = {
  [VegetarianDiet.VEGAN]: 'pro:diet.hint.vegan',
  [VegetarianDiet.LACTO_VEGETARIAN]: 'pro:diet.hint.lactoVegetarian',
  [VegetarianDiet.OVO_VEGETARIAN]: 'pro:diet.hint.ovoVegetarian',
  [VegetarianDiet.LACTO_OVO_VEGETARIAN]: 'pro:diet.hint.lactoOvoVegetarian',
}

export const i18nVegetarianDiets: I18nExtendedElement[] = vegetarianDietsKeys.map(
  (key) => ({
    id: key,
    i18nLabel: i18nLabelMappings[key],
    i18nHint: i18nHintMappings[key],
  })
)
export const i18nVegetarianDietsById: {
  [key: string]: I18nExtendedElement
} = i18nVegetarianDiets.reduce(
  (
    accumulator: {
      [key: string]: I18nExtendedElement
    },
    i18nVegetarianDiet
  ) => {
    accumulator[i18nVegetarianDiet.id] = i18nVegetarianDiet
    return accumulator
  },
  {}
)
