import { combineReducers } from '@reduxjs/toolkit'
// Reducers
import { deviceSlice } from './deviceSlice'
import { guestAwsSlice } from './guestAwsSlice'
import { guestFiltersSlice } from './guestFiltersSlice'
import { guestLocaleSlice } from './guestLocaleSlice'
import { guestSavedSlice } from './guestSavedSlice'
import { adminSlice } from './adminSlice'
import { hostEditModeSlice } from './hostEditModeSlice'
import { hostPreviewLanguageSlice } from './hostPreviewLanguageSlice'
import { hostUserSlice } from './hostUserSlice'
import { localPlaceSlice } from './localPlaceSlice'

export const rootReducer = combineReducers({
  [adminSlice.name]: adminSlice.reducer,
  [deviceSlice.name]: deviceSlice.reducer,

  [guestAwsSlice.name]: guestAwsSlice.reducer,
  [guestFiltersSlice.name]: guestFiltersSlice.reducer,
  [guestLocaleSlice.name]: guestLocaleSlice.reducer,
  [guestSavedSlice.name]: guestSavedSlice.reducer,

  [hostEditModeSlice.name]: hostEditModeSlice.reducer,
  [hostPreviewLanguageSlice.name]: hostPreviewLanguageSlice.reducer,
  [hostUserSlice.name]: hostUserSlice.reducer,

  [localPlaceSlice.name]: localPlaceSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
