import { I18nElement } from '../types'

export enum Allergen {
  BUCKWHEAT = 'BUCKWHEAT',
  CELERY = 'CELERY',
  CORIANDER = 'CORIANDER',
  CORN = 'CORN',
  CRUSTACEANS = 'CRUSTACEANS',
  EGGS = 'EGGS',
  FISH = 'FISH',
  FRUIT = 'FRUIT',
  GARLIC = 'GARLIC',
  GLUTEN = 'GLUTEN',
  LUPIN = 'LUPIN',
  MILK = 'MILK',
  MOLLUSCS = 'MOLLUSCS',
  MUSTARD = 'MUSTARD',
  PEANUTS = 'PEANUTS',
  PERU_BALSAM = 'PERU_BALSAM',
  POULTRY = 'POULTRY',
  RED_MEAT = 'RED_MEAT',
  RICE = 'RICE',
  SESAME = 'SESAME',
  SOY = 'SOY',
  SULPHITES = 'SULPHITES',
  TREE_NUTS = 'TREE_NUTS',
}

export const allergensKeys = Object.keys(Allergen)

// I18N
export const i18nAllergensMappings: { [key: string]: string } = {
  [Allergen.BUCKWHEAT]: 'allergen.buckwheat',
  [Allergen.CELERY]: 'allergen.celery',
  [Allergen.CORIANDER]: 'allergen.coriander',
  [Allergen.CORN]: 'allergen.corn',
  [Allergen.CRUSTACEANS]: 'allergen.crustaceans',
  [Allergen.EGGS]: 'allergen.eggs',
  [Allergen.FISH]: 'allergen.fish',
  [Allergen.FRUIT]: 'allergen.fruit',
  [Allergen.GARLIC]: 'allergen.garlic',
  [Allergen.GLUTEN]: 'allergen.gluten',
  [Allergen.LUPIN]: 'allergen.lupin',
  [Allergen.MILK]: 'allergen.milk',
  [Allergen.MOLLUSCS]: 'allergen.molluscs',
  [Allergen.MUSTARD]: 'allergen.mustard',
  [Allergen.PEANUTS]: 'allergen.peanuts',
  [Allergen.PERU_BALSAM]: 'allergen.peruBalsam',
  [Allergen.POULTRY]: 'allergen.poultry',
  [Allergen.RED_MEAT]: 'allergen.redMeat',
  [Allergen.RICE]: 'allergen.rice',
  [Allergen.SESAME]: 'allergen.sesame',
  [Allergen.SOY]: 'allergen.soy',
  [Allergen.SULPHITES]: 'allergen.sulphites',
  [Allergen.TREE_NUTS]: 'allergen.treeNuts',
}

export const i18nAllergens: I18nElement[] = allergensKeys.map((key) => ({
  id: key,
  i18nLabel: i18nAllergensMappings[key],
}))

// STATUS
export enum AllergenStatus {
  MAYBE = 'MAYBE',
  YES = 'YES',
  YESBUT = 'YESBUT',
  NO = 'NO',
}

export const allergenStatusKeys = Object.keys(AllergenStatus)
