import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Libs
import { Allergen, VegetarianDiet, ReligiousDiet } from '../../../lib/constants'

type GuestFilters = {
  areOpen: boolean
  allergens: Allergen[]
  vegetarianDiet: VegetarianDiet
  religiousDiet: ReligiousDiet
}

const initialState: GuestFilters = {
  areOpen: false,
  allergens: [],
  vegetarianDiet: VegetarianDiet.NONE,
  religiousDiet: ReligiousDiet.NONE,
}

export const guestFiltersSlice = createSlice({
  name: 'guestFilters',
  initialState,
  reducers: {
    setAreFiltersOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      areOpen: action.payload,
    }),
    setAllergensFilter: (state, action: PayloadAction<Allergen[]>) => ({
      ...state,
      allergens: action.payload,
    }),
    setVegetarianDietFilter: (state, action: PayloadAction<VegetarianDiet>) => ({
      ...state,
      vegetarianDiet: action.payload,
    }),
    setReligiousDietFilter: (state, action: PayloadAction<ReligiousDiet>) => ({
      ...state,
      religiousDiet: action.payload,
    }),
  },
})

export const {
  setAreFiltersOpen,
  setAllergensFilter,
  setVegetarianDietFilter,
  setReligiousDietFilter,
} = guestFiltersSlice.actions
