import { COUNTRY_KEYS } from './countries'
import { SUPPORTED_GUEST_APP_LANGUAGES } from './languages'

const getLocalesFromLanguageKeysAndCountryKey = (
  languageKeys: string[],
  countryKeys: string[]
) =>
  languageKeys.reduce((prevAllLocales: string[], currentLanguageKey) => {
    const allLocalesForCountry = countryKeys.reduce(
      (prevCountryLocales: string[], currentCountryKey) => {
        prevCountryLocales.push(`${currentLanguageKey}-${currentCountryKey}`)
        return prevCountryLocales
      },
      []
    )

    return prevAllLocales.concat(allLocalesForCountry)
  }, [])

// export const ALL_LOCALES = getLocalesFromLanguageKeysAndCountryKey(
//   LANGUAGE_KEYS,
//   COUNTRY_KEYS
// )
export const SUPPORTED_GUEST_APP_LOCALES = getLocalesFromLanguageKeysAndCountryKey(
  SUPPORTED_GUEST_APP_LANGUAGES,
  COUNTRY_KEYS
)

export const SUPPORTED_GUEST_APP_LANGUAGES_AND_LOCALES = SUPPORTED_GUEST_APP_LANGUAGES.concat(
  SUPPORTED_GUEST_APP_LOCALES
)
