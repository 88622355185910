import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
// Components
import { App } from './components/App'
// Reducers
import { setupStore } from './store'
// Service Worker
// import * as serviceWorker from './serviceWorker'

const store = setupStore()

const renderApp = () =>
  render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./components/App', renderApp)
}

renderApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
