import {
  HostPageName,
  GuestPageName,
  HostComponentName,
  GuestComponentName,
} from '../analytics'

// Graphql Data & Status
export type PlaceData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  isHidden?: boolean
  imagesNames: string[]
  uploadedMenus?: string[]

  name: string
  urlName: string
  locale: string // => language
  otherLocales: string[] // => otherLanguages
  currency: string
  country: string

  coordinateLatitude?: number
  coordinateLongitude?: number

  address?: string
  phone?: string
  whatsapp?: string
  email?: string
  webpage?: string
  instagram?: string
  facebook?: string
  twitter?: string

  isPro?: boolean

  i18ns: {
    items: PlaceI18nData[]
  }
  footerPricePhrases?: {
    items: PlacePricePhraseData[]
  }
  groups: {
    items?: GroupData[]
  }
  phrases: {
    items?: PhraseData[]
  }
}

export type PlaceI18nData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  country: string
  locale: string
  name?: string
  description?: string
  footerDescription?: string

  place: PlaceData
}

export type PlacePricePhraseData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  orderIndex: number

  price?: string
  phraseID?: string
}

export type PhraseData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  placeID: string
  name: string

  i18ns: {
    items: PhraseI18nData[]
  }
}

export type PhraseI18nData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  phraseID: string
  locale: string
  name?: string
}

export type PricePhraseData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  orderIndex: number
  price?: string

  phrase?: PhraseData
}

export type GroupData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  isHidden?: boolean
  orderIndex: number
  name: string

  imagesNames?: string[]

  place: PlaceData
  i18ns: {
    items?: GroupI18nData[]
  }

  prices?: {
    items?: PricePhraseData[]
  }
  things: {
    items?: ThingData[]
  }
  phrasesLinks: {
    items?: GroupPhraseLinkData[]
  }
}

export type GroupI18nData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  locale: string
  name?: string
  description?: string

  group: GroupData
}

export type GroupPhraseLinkData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  groupID: string
  orderIndex: number

  phrase: PhraseData
}

export type ThingData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  isHidden?: boolean
  orderIndex: number
  name: string

  buckwheat?: string
  celery?: string
  coriander?: string
  corn?: string
  crustaceans?: string
  eggs?: string
  fish?: string
  fruit?: string
  garlic?: string
  gluten?: string
  lupin?: string
  milk?: string
  molluscs?: string
  mustard?: string
  peanuts?: string
  peruBalsam?: string
  poultry?: string
  redMeat?: string
  rice?: string
  sesame?: string
  soy?: string
  sulphites?: string
  treeNuts?: string

  vegetarianDiets?: string[]
  religiousDiets?: string[]
  price?: string

  imagesNames: string[]

  group: GroupData
  i18ns: {
    items?: ThingI18nData[]
  }
  prices?: {
    items: PricePhraseData[]
  }
  thingsLinks?: {
    items?: ThingLinkData[]
  }
  phrasesLinks?: {
    items?: PhraseLinkData[]
  }
}

export type ThingI18nData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  locale: string
  name?: string
  description?: string

  thing: ThingData
}

export type ThingLinkData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  thingID: string
  orderIndex: number

  thing: ThingData
}

export type PhraseLinkData = {
  id: string
  owner: string
  createdAt: string
  updatedAt: string

  thingID: string
  orderIndex: number

  phrase: PhraseData
}

export enum GraphQLStatus {
  Ready = 'ready',
  Loading = 'loading',
  Error = 'error',
}

// Validations
export type Validation = 'success' | 'warning' | 'error' | undefined

export type I18nElement = {
  id: string
  i18nLabel: string
}

export type I18nExtendedElement = {
  id: string
  i18nLabel: string
  i18nHint: string
}

export type Analytics = {
  category: HostPageName | GuestPageName | HostComponentName | GuestComponentName
  label: string
}
