import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Store
import { resetStore } from '../../actions'

type HostUser = {
  graphqlOwner: string
  imageOwnerId: string
}

export const EMPTY_HOST_USER: HostUser = {
  graphqlOwner: '',
  imageOwnerId: '',
}
const initialState = {
  ...EMPTY_HOST_USER,
  isAwsConfigured: false,
}

export const hostUserSlice = createSlice({
  name: 'hostUser',
  initialState,
  reducers: {
    setHostUser: (state, action: PayloadAction<HostUser>) => ({
      ...state,
      ...action.payload,
    }),
    setIsAwsConfigured: (state) => ({ ...state, isAwsConfigured: true }),
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, (state) => ({
      ...EMPTY_HOST_USER,
      isAwsConfigured: state.isAwsConfigured,
    }))
  },
})

export const { setHostUser, setIsAwsConfigured } = hostUserSlice.actions
