import { I18nElement } from '../types'

export enum ReligiousDiet {
  HALAL = 'HALAL',
  KOSHER = 'KOSHER',
  JHATKA = 'JHATKA',
  SATTVIC = 'SATTVIC',
  MORMON = 'MORMON',
  ITAL = 'ITAL',
  NONE = 'NONE',
}

export const religiousDietsKeys = Object.keys(ReligiousDiet)

// I18N
const i18nLabelMappings: { [key: string]: string } = {
  [ReligiousDiet.HALAL]: 'core:diet.halal',
  [ReligiousDiet.KOSHER]: 'core:diet.kosher',
  [ReligiousDiet.JHATKA]: 'core:diet.jhatka',
  [ReligiousDiet.SATTVIC]: 'core:diet.sattvic',
  [ReligiousDiet.MORMON]: 'core:diet.mormon',
  [ReligiousDiet.ITAL]: 'core:diet.ital',
  [ReligiousDiet.NONE]: 'core:diet.none',
}

export const i18nReligiousDiets: I18nElement[] = religiousDietsKeys.map((key) => ({
  id: key,
  i18nLabel: i18nLabelMappings[key],
}))
export const hostSelectableI18nReligiousDiets: I18nElement[] = i18nReligiousDiets.filter(
  (diet) => diet.id !== ReligiousDiet.NONE
)
export const i18nReligiousDietsById: {
  [key: string]: I18nElement
} = i18nReligiousDiets.reduce(
  (
    accumulator: {
      [key: string]: I18nElement
    },
    i18nReligiousDiet
  ) => {
    accumulator[i18nReligiousDiet.id] = i18nReligiousDiet
    return accumulator
  },
  {}
)
