import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Store
import { resetStore } from '../../actions'

const initialState = {
  needsAws: false,
  isAwsReady: false,
}

export const guestAwsSlice = createSlice({
  name: 'guestAws',
  initialState,
  reducers: {
    setNeedsAws: (state, action: PayloadAction<boolean>) => ({
      ...state,
      needsAws: action.payload,
    }),
    setIsAwsReady: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAwsReady: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, (_) => initialState)
  },
})

export const { setNeedsAws, setIsAwsReady } = guestAwsSlice.actions
