import { Spinner as ZenSpinner } from '@zendeskgarden/react-loaders'
import React from 'react'
// Libs
import { PRIMARY_COLOR } from '../../../lib/theme'

export const Spinner = ({
  color,
  duration,
  size,
  delayMS,
  className,
}: {
  color?: string
  duration?: number
  size?: string
  delayMS?: number
  className?: any
}) => (
  <ZenSpinner
    className={className}
    color={!!color ? color : PRIMARY_COLOR}
    size={!!size ? size : '42px'}
    delayMS={!!delayMS ? delayMS : 0}
    duration={!!duration ? duration : 850}
  />
)
