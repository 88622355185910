import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Store
import { resetStore } from '../../actions'

const initialState = false

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setIsAdmin: (_, action: PayloadAction<boolean>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, (_) => initialState)
  },
})

export const { setIsAdmin } = adminSlice.actions
