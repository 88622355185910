import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Store
import { resetStore } from '../../actions'

const initialState = ''

export const hostPreviewLanguageSlice = createSlice({
  name: 'hostPreviewLanguage',
  initialState,
  reducers: {
    setHostPreviewLanguage: (_, action: PayloadAction<string>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, (_) => initialState)
  },
})

export const { setHostPreviewLanguage } = hostPreviewLanguageSlice.actions
