import { createGlobalStyle } from 'styled-components'
// Libs
import { DARK_COLOR, PRIMARY_COLOR } from '../../lib/theme'

export const GlobalStyle = createGlobalStyle`
html {
  background-color: ${DARK_COLOR};
  -webkit-text-size-adjust: 100%;
}

html, body, #root {
  height: 100%;
  scroll-behavior: smooth;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

#root > div {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: ${PRIMARY_COLOR};

  :active {
    text-decoration: none;
  }
  :hover {
    text-decoration: underline;
  }
}
`
